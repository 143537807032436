import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from '../../global/Header';
import QuestionBox from './QuestionBox';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { Formik } from 'formik';
import * as yup from "yup";
import { tokens } from '../../theme';

function QuestionsPage() {
    const colors = tokens();
    const [questionSearchKey, setQuestionSearchKey] = useState("");
    const [infoSearchKey, setInfoSearchKey] = useState("");
    const [optionsSearchKey, setOptionsSearchKey] = useState("");
    const [categoryId, setCategoryId] = useState(-1);
    const [questions, setQuestions] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [targetQuestion, setTargetQuestion] = useState(null);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [questionToRemove, setQuestionToRemove] = useState(null);
    const [categories, setCategories] = useState([]);
    const [targetCategory, setTargetCategory] = useState(null);
    const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
    const [categoryEditDialogOpen, setCategoryEditDialogOpen] = useState(false);

    const fetchQuestions = () => {
        axios.get(`${process.env.REACT_APP_API_URL}quiz/getFilteredQuestions?questionSearchKey=${questionSearchKey}&infoSearchKey=${infoSearchKey}&optionsSearchKey=${optionsSearchKey}&categoryId=${categoryId}&startIndex=${startIndex}&limit=50`)
            .catch((err) => {
                console.log("err: " + err);
                setQuestions([]);
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.questions && response.data.questions.length === 50);
                    if (startIndex === 0) {
                        setQuestions(response.data.questions);
                    } else {
                        setQuestions((prev) => [...prev, ...response.data.questions]);
                    }
                }
            });
    }

    const fetchQuizCategories = () => {
        axios.get(`${process.env.REACT_APP_API_URL}quiz/getQuizCategories`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setCategories(response.data.categories.sort((ct1, ct2) => ct1.id - ct2.id));
                }
            });
    }

    useEffect(() => {
        fetchQuestions();
    }, [categoryId, startIndex]);

    useEffect(() => {
        fetchQuizCategories();
    }, []);

    const handleClose = async () => {
        setDialogOpen(false);
        setTargetQuestion(null);
    }

    var initialValues = {
        category: targetQuestion?.category,
        answer: targetQuestion?.answer,
        info: targetQuestion?.info ? targetQuestion.info : "",
        question: targetQuestion?.question ? targetQuestion.question : "",
        a: targetQuestion?.a ? targetQuestion.a : "",
        b: targetQuestion?.b ? targetQuestion.b : "",
        c: targetQuestion?.c ? targetQuestion.c : "",
        d: targetQuestion?.d ? targetQuestion.d : ""
    }

    var validationObject = {
        category: yup.number().min(0, "Please Select").required("required"),
        answer: yup.string().required("required"),
        info: yup.string().required("required"),
        question: yup.string().required("required"),
        a: yup.string().required("required"),
        b: yup.string().required("required"),
        c: yup.string().required("required"),
        d: yup.string().required("required")
    }
    const questionSchema = yup.object().shape(validationObject);

    const handleFormSubmit = async (values) => {
        if (targetQuestion) {
            if (targetQuestion.a) {
                if (targetQuestion.language === "turkish") {
                    //edit question
                    await axios.patch(`${process.env.REACT_APP_API_URL}quiz/updateQuestion?questionId=${targetQuestion.id}`, { question: values }).catch((err) => {
                        console.log("err: " + err);
                    }).then((response) => {
                        if (response && response.data.success === true && response.data.status === 201) {
                            handleClose();
                            if (startIndex === 0) {
                                fetchQuestions();
                            } else {
                                setStartIndex(0);
                            }
                        }
                    });
                } else {
                    //edit language
                    await axios.patch(`${process.env.REACT_APP_API_URL}quiz/updateQuestionTranslationForWeb?questionId=${targetQuestion.id}&language=${targetQuestion.language}`, { translation: values }).catch((err) => {
                        console.log("err: " + err);
                    }).then((response) => {
                        if (response && response.data.success === true && response.data.status === 201) {
                            handleClose();
                            if (startIndex === 0) {
                                fetchQuestions();
                            } else {
                                setStartIndex(0);
                            }
                        }
                    });
                }
            } else {
                //add new language to the question
                await axios.post(`${process.env.REACT_APP_API_URL}quiz/addQuestionTranslation`, {
                    questionId: targetQuestion.id,
                    info: values.info,
                    question: values.question,
                    a: values.a,
                    b: values.b,
                    c: values.c,
                    d: values.d,
                    language: targetQuestion.language
                }).catch((err) => {
                    console.log("err: " + err);
                }).then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        handleClose();
                        if (startIndex === 0) {
                            fetchQuestions();
                        } else {
                            setStartIndex(0);
                        }
                    }
                });
            }
        } else {
            //add new question
            await axios.post(`${process.env.REACT_APP_API_URL}quiz/addQuestion`, {
                ...values
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleClose();
                    if (startIndex === 0) {
                        fetchQuestions();
                    } else {
                        setStartIndex(0);
                    }
                }
            });
        }
    }

    useEffect(() => {
        if (targetQuestion) {
            setDialogOpen(true);
        }
    }, [targetQuestion])


    const updateQuestion = (q) => {
        setTargetQuestion(q);
    }

    useEffect(() => {
        if (questionToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [questionToRemove]);

    const handleRemoveDialogClose = async () => {
        setRemoveDialogOpen(false);
        setQuestionToRemove(null);
    }

    const handleQuestionToRemove = (q) => {
        if (q) {
            setQuestionToRemove(q);
        }
    }

    const removeQuestion = async () => {
        if (questionToRemove.language === "turkish") {
            await axios.delete(`${process.env.REACT_APP_API_URL}quiz/deleteQuestion?id=${questionToRemove.id}`).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && (response.data.status === 200 || response.data.status === 201)) {
                    if (startIndex === 0) {
                        fetchQuestions();
                    } else {
                        setStartIndex(0);
                    }
                    handleRemoveDialogClose();
                }
            });
        } else {
            await axios.delete(`${process.env.REACT_APP_API_URL}quiz/deleteQuestionTranslation?id=${questionToRemove.id}&language=${questionToRemove.language}`).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && (response.data.status === 200 || response.data.status === 201)) {
                    if (startIndex === 0) {
                        fetchQuestions();
                    } else {
                        setStartIndex(0);
                    }
                    handleRemoveDialogClose();
                }
            });
        }

    }

    const handleCategoryDialogClose = () => {
        setCategoryDialogOpen(false);
    }

    const onCategorySelected = (category) => {
        setCategoryDialogOpen(false);
        setTargetCategory(category);
    }

    const handleCategoryEditDialogClose = async () => {
        setCategoryEditDialogOpen(false);
        setTargetCategory(null);
    }

    useEffect(() => {
        if (targetCategory) {
            setCategoryEditDialogOpen(true);
        }
    }, [targetCategory])

    var initialCategoryValues = {
        point: targetCategory?.point ? targetCategory.point : 0,
        reduce_point: targetCategory?.reduce_point ? targetCategory.reduce_point : 0
    }

    const categorySchema = yup.object().shape({
        point: yup.number().min(0, "Please Select").required("required"),
        reduce_point: yup.number().min(0, "Please Select").required("required")
    });

    const handleCategoryEditFormSubmit = async (values) => {
        if (targetCategory) {
            await axios.patch(`${process.env.REACT_APP_API_URL}quiz/updateCategoryPoints`, { id: targetCategory.id, ...values }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    fetchQuizCategories();
                    handleCategoryEditDialogClose();
                }
            });
        }
    }

    return (
        <Box sx={{ p: "75px" }}>
            <Dialog onClose={handleCategoryDialogClose} open={categoryDialogOpen}>
                <DialogTitle><Typography variant='h3'>Select Category</Typography></DialogTitle>
                <List sx={{ pt: 0 }}>
                    {categories.map((ct) => (
                        <ListItem disableGutters key={ct.id + "qzct"}>
                            <ListItemButton onClick={() => onCategorySelected(ct)}>
                                <ListItemText primary={ct.name} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Dialog>
            <Dialog
                sx={{ margin: "0 auto" }} open={dialogOpen}
                onClose={handleClose}>
                <Box m="20px" textAlign="center">
                    {targetQuestion &&
                        <Typography variant='h3'>
                            Edit Question ({targetQuestion?.language[0].toUpperCase() + targetQuestion?.language.slice(1)})
                        </Typography>
                    }
                    {!targetQuestion &&
                        <Typography variant='h3'>
                            New Question (Turkish)
                        </Typography>
                    }
                </Box>
                <DialogContent>
                    <Box>
                        <Formik
                            onSubmit={handleFormSubmit}
                            initialValues={initialValues}
                            validationSchema={questionSchema}
                        >
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mx="75px" mb="25px">
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Question"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"question"}
                                            value={values.question}
                                            error={!!touched.question && !!errors.question}
                                            helperText={touched.question && errors.question}
                                            sx={{ gridColumn: "span 4", marginTop: "20px" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Info"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"info"}
                                            value={values.info}
                                            error={!!touched.info && !!errors.info}
                                            helperText={touched.info && errors.info}
                                            sx={{ gridColumn: "span 4", marginTop: "20px" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="A"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"a"}
                                            value={values.a}
                                            error={!!touched.a && !!errors.a}
                                            helperText={touched.a && errors.a}
                                            sx={{ gridColumn: "span 1", mt: "20px" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="B"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"b"}
                                            value={values.b}
                                            error={!!touched.b && !!errors.b}
                                            helperText={touched.b && errors.b}
                                            sx={{ gridColumn: "span 1", mt: "20px" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="C"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"c"}
                                            value={values.c}
                                            error={!!touched.c && !!errors.c}
                                            helperText={touched.c && errors.c}
                                            sx={{ gridColumn: "span 1", mt: "20px" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="D"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"d"}
                                            value={values.d}
                                            error={!!touched.d && !!errors.d}
                                            helperText={touched.d && errors.d}
                                            sx={{ gridColumn: "span 1", mt: "20px" }}
                                        />
                                        {(!targetQuestion || (targetQuestion && targetQuestion.language === "turkish")) &&
                                            <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2" }}>
                                                <InputLabel id="filter-label">Answer</InputLabel>
                                                <Select
                                                    labelId="filter-label"
                                                    label="Answer"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    name={"answer"}
                                                    value={values.answer}
                                                    error={!!touched.answer && !!errors.answer}

                                                >
                                                    <MenuItem value={"a"}>A</MenuItem>
                                                    <MenuItem value={"b"}>B</MenuItem>
                                                    <MenuItem value={"c"}>C</MenuItem>
                                                    <MenuItem value={"d"}>D</MenuItem>
                                                </Select>
                                            </FormControl>
                                        }
                                        {(!targetQuestion || (targetQuestion && targetQuestion.language === "turkish")) &&
                                            <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2" }}>
                                                <InputLabel id="filter-label">Category</InputLabel>
                                                <Select
                                                    labelId="filter-label"
                                                    label="Category"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    name={"category"}
                                                    value={values.category}
                                                    error={!!touched.category && !!errors.category}

                                                >
                                                    <MenuItem value={8}>A1A2</MenuItem>
                                                    <MenuItem value={9}>Academic</MenuItem>
                                                    <MenuItem value={10}>B1B2</MenuItem>
                                                    <MenuItem value={11}>C1C2</MenuItem>
                                                    <MenuItem value={12}>Idioms</MenuItem>
                                                    <MenuItem value={13}>Phrasal Verbs</MenuItem>
                                                </Select>
                                            </FormControl>
                                        }
                                    </Box>
                                    <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                        <Button type="submit" color="secondary" variant="contained">
                                            <Typography variant='h5'>Save</Typography>
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog
                sx={{ margin: "0 auto" }} open={categoryEditDialogOpen}
                onClose={handleCategoryEditDialogClose}>
                <Box m="20px" textAlign="center">
                    <Typography variant='h3'>
                        Edit Category Points ({targetCategory?.name.toUpperCase()})
                    </Typography>
                </Box>
                <DialogContent>
                    <Box>
                        <Formik
                            onSubmit={handleCategoryEditFormSubmit}
                            initialValues={initialCategoryValues}
                            validationSchema={categorySchema}
                        >
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box display="grid" gap="15px" gridTemplateColumns="repeat(1, minmax(0, 1fr))" mx="75px" mb="25px">
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="number"
                                            label="Points To Earn"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"point"}
                                            value={values.point}
                                            error={!!touched.point && !!errors.point}
                                            helperText={touched.point && errors.point}
                                            sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="number"
                                            label="Points to Lose"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"reduce_point"}
                                            value={values.reduce_point}
                                            error={!!touched.reduce_point && !!errors.reduce_point}
                                            helperText={touched.reduce_point && errors.reduce_point}
                                            sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                        />
                                    </Box>
                                    <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                        <Button type="submit" color="secondary" variant="contained">
                                            <Typography variant='h5'>Save</Typography>
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{questionToRemove?.language === "turkish" ? "Are you sure you want to delete the question?" : "Are you sure you want to delete the question's translation?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {questionToRemove?.language === "turkish" ?
                            "Question and all related data (translations etc.) will be deleted. You cannot undo this operation, you can add the same question again if you wish." :
                            "Question translation will be deleted. You cannot undo this operation, you can add the same translation again if you wish."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>Cancel</Button>
                    <Button onClick={() => removeQuestion()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <Box>
                <Box display="flex" alignItems="center" gap="20px">
                    <Header title="Questions" subtitle="You can see and manage questions from this page." />
                    <Button variant='contained' color='success' sx={{ m: 1, width: '25ch' }} onClick={() => {
                        setDialogOpen(true);
                    }} >
                        Add New Question
                    </Button>


                    <Button variant='contained' color='warning' sx={{ m: 1, width: '25ch' }} onClick={() => {
                        setCategoryDialogOpen(true);
                    }} >
                        Categories
                    </Button>
                </Box>

                <Box display="flex" alignItems="center" gap="20px">
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="question-search-box">Question</InputLabel>
                        <OutlinedInput
                            id="question-search-box"
                            type='text'
                            onChange={(e) => {
                                setQuestionSearchKey(e.target.value ? e.target.value : "");
                            }}
                            label="Question"
                        />
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="question-root-search-box">Question Root</InputLabel>
                        <OutlinedInput
                            id="question-root-search-box"
                            type='text'
                            onChange={(e) => {
                                setInfoSearchKey(e.target.value ? e.target.value : "");
                            }}
                            label="Question Root"
                        />
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="question-options-search-box">Question Options</InputLabel>
                        <OutlinedInput
                            id="question-options-search-box"
                            type='text'
                            onChange={(e) => {
                                setOptionsSearchKey(e.target.value ? e.target.value : "");
                            }}
                            label="Question Options"
                        />
                    </FormControl>

                    <IconButton
                        aria-label="search button"
                        sx={{ background: colors.grey[900], borderRadius: "10px", padding: "12px" }}
                        onClick={() => {
                            setQuestions([]);
                            if (startIndex === 0) {
                                fetchQuestions();
                            } else {
                                setStartIndex(0);
                            }
                        }}
                        //onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        <SearchIcon />
                    </IconButton>
                </Box>


                <Box mt="10px">
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="category">Category</InputLabel>
                        <Select
                            id="category"
                            value={categoryId}
                            label="Category"
                            onChange={(e) => {
                                setStartIndex(0);
                                setQuestions([]);
                                setCategoryId(e.target.value);
                            }}
                        >
                            <MenuItem value={-1}>All</MenuItem>
                            <MenuItem value={8}>A1A2</MenuItem>
                            <MenuItem value={9}>Academic</MenuItem>
                            <MenuItem value={10}>B1B2</MenuItem>
                            <MenuItem value={11}>C1C2</MenuItem>
                            <MenuItem value={12}>Idioms</MenuItem>
                            <MenuItem value={13}>Phrasal Verbs</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Box mt="20px">
                <InfiniteScroll
                    dataLength={questions.length}
                    next={() => {
                        setStartIndex(questions.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>You are viewing all questions.</b>
                        </p>
                    }
                >
                    <Box display="flex" flexWrap="wrap" gap="20px">
                        {questions.map(q => <QuestionBox key={q.id} question={q} updateQuestion={updateQuestion} removeQuestion={handleQuestionToRemove} />)}
                    </Box>
                </InfiniteScroll>
            </Box>
        </Box>
    )
}

export default QuestionsPage