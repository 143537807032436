import React, { useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';

function AdSettingsDialog({ dialogOpen, handleClose, settings, fetchSettings }) {

    var initialValues = {
        ad_limit: settings?.ad_limit !== undefined && settings?.ad_limit !== null ? settings?.ad_limit : 1,
        card_counter: settings?.card_counter !== undefined && settings?.card_counter !== null ? settings?.card_counter : 1,
        is_random: settings?.is_random !== undefined && settings?.is_random !== null ? settings?.is_random : 1,
    }

    var validationObject = {
        ad_limit: yup.number().min(0, "Can be at least 0").required("required"),
        card_counter: yup.number().min(0, "Can be at least 0").required("required"),
        is_random: yup.number().min(0, "Please Select").required("required")
    }

    const settingsSchema = yup.object().shape(validationObject);

    const handleFormSubmit = async (values) => {
        if (settings.is_random !== values.is_random) {
            await axios.patch(`${process.env.REACT_APP_API_URL}settings/updateAdRandom`, {
                is_random: values.is_random
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                }
            });
        }
        await axios.patch(`${process.env.REACT_APP_API_URL}settings/updateAdSettings`, {
            ...values
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                fetchSettings();
                handleClose();
            }
        });
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" mx="150px" textAlign="center">
                <Typography variant='h3'>
                    Ad Settings
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={settingsSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mb="25px">

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Rewarded Ad Limit To Get Diamonds (Hourly)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"ad_limit"}
                                        value={values.ad_limit}
                                        error={!!touched.ad_limit && !!errors.ad_limit}
                                        helperText={touched.ad_limit && errors.ad_limit}
                                        sx={{ gridColumn: "span 2", marginTop: "10px" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Card Count to Show Ad"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"card_counter"}
                                        value={values.card_counter}
                                        error={!!touched.card_counter && !!errors.card_counter}
                                        helperText={touched.card_counter && errors.card_counter}
                                        sx={{ gridColumn: "span 1", marginTop: "10px" }}
                                    />

                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2", marginTop: "10px" }}>
                                        <InputLabel id="filter-label">Random Ad</InputLabel>
                                        <Select
                                            labelId="filter-label"
                                            label="Random Ad"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"is_random"}
                                            value={values.is_random}
                                            error={!!touched.is_random && !!errors.is_random}
                                        >
                                            <MenuItem value={0}>Passive</MenuItem>
                                            <MenuItem value={1}>Active</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default AdSettingsDialog