import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import { tokens } from '../../../../theme'
import MoreVertIcon from '@mui/icons-material/MoreVert';

function ReadingExamCategoryBox({ readingExamCategory, editReadingExamCategory, removeReadingExamCategory }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const colors = tokens();
    return (
        <Box mt={"30px"} sx={{ gridColumn: "span 1" }} >
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    editReadingExamCategory(readingExamCategory);
                }}>
                    Edit
                </MenuItem>
                <MenuItem onClick={() => {
                    removeReadingExamCategory(readingExamCategory);
                    handleClose()
                }}>
                    Remove
                </MenuItem>
            </Menu>

            <Box position="relative" bgcolor={colors.grey[900]} borderRadius="10px">
                <Box position="absolute" top={0} right={0}>

                    <IconButton
                        id="open-menu-button"
                        aria-controls={open ? 'menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <MoreVertIcon sx={{ scale: "1.2" }} />
                    </IconButton>

                </Box>
                <Box display="flex" gap="10px">
                    <Box mt={"5px"} mr={"35px"} padding={"20px"}>
                        <Typography variant='h3' fontWeight="600" >
                            {readingExamCategory.title}
                        </Typography>
                        <Typography variant='h4' mt={"5px"} >
                            {readingExamCategory.description}
                        </Typography>
                        <Typography variant='h6' mt={"10px"} >
                            Number of readings: {readingExamCategory.reading_count}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ReadingExamCategoryBox