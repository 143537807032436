import { Box, Button, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { tokens } from '../../../theme'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
const languagePrefixes = {
    turkish: "tr",
    french: "fr",
    german: "de",
    italian: "it",
    portuguese: "pt",
    spanish: "sp"
}


function SubCategoryBox({ subCategory, category, editSubCategory, removeSubCategory, fetchSubCategories }) {
    const colors = tokens();
    const [selectedLanguage, setSelectedLanguage] = useState("turkish");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const updateSubCategoryPosition = (position) => {
        axios.patch(`${process.env.REACT_APP_API_URL}word/${position > 0 ? "increase" : "decrease"}SubCategoryPosition?id=${subCategory.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    fetchSubCategories();
                }
            });
    }

    return (
        <Box width="500px">
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    editSubCategory({ ...subCategory, name: subCategory[languagePrefixes[selectedLanguage] + "_name"], language: selectedLanguage });
                }}>
                    Edit
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    removeSubCategory({ ...subCategory, language: selectedLanguage });
                }}>
                    Remove
                </MenuItem>
            </Menu>
            <Box position="relative" bgcolor={colors.grey[900]} p="20px" borderRadius="10px">
                <Box position="absolute" top={15} right={15}>
                    <IconButton
                        id="open-menu-button"
                        aria-controls={open ? 'menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <MoreVertIcon sx={{ scale: "1.2" }} />
                    </IconButton>
                </Box>

                <Box display="flex" gap="10px">
                    <Box component={"img"} height="100px" maxWidth="50px" src={subCategory.thumbnail_link} borderRadius="10px 0 0 10px" />
                    <Box>
                        <Typography variant='h3' fontWeight="600">
                            {subCategory[languagePrefixes[selectedLanguage] + "_name"] ? subCategory[languagePrefixes[selectedLanguage] + "_name"] : "-"}
                        </Typography>
                        <Box display="flex" mt="10px" gap="10px">
                            <Tooltip title="Turkish">
                                <Box
                                    sx={{
                                        ...(selectedLanguage !== "turkish" ? {
                                            filter: "saturate(0%)",
                                            WebkitFilter: "saturate(0%)"
                                        } : {}),
                                        ":hover": {
                                            filter: "saturate(100%)",
                                            WebkitFilter: "saturate(100%)",
                                            cursor: "pointer"
                                        }
                                    }}
                                    onClick={() => setSelectedLanguage("turkish")}
                                    component="img"
                                    src='/flags/turkey.png'
                                />
                            </Tooltip>

                            <Tooltip title="French">
                                <Box
                                    sx={{
                                        ...(selectedLanguage !== "french" ? {
                                            filter: "saturate(0%)",
                                            WebkitFilter: "saturate(0%)"
                                        } : {}),
                                        ":hover": {
                                            filter: "saturate(100%)",
                                            WebkitFilter: "saturate(100%)",
                                            cursor: "pointer"
                                        }
                                    }}
                                    onClick={() => setSelectedLanguage("french")}
                                    component="img"
                                    src='/flags/france.png'
                                />
                            </Tooltip>

                            <Tooltip title="German">
                                <Box
                                    sx={{
                                        ...(selectedLanguage !== "german" ? {
                                            filter: "saturate(0%)",
                                            WebkitFilter: "saturate(0%)"
                                        } : {}),
                                        ":hover": {
                                            filter: "saturate(100%)",
                                            WebkitFilter: "saturate(100%)",
                                            cursor: "pointer"
                                        }
                                    }}
                                    onClick={() => setSelectedLanguage("german")}
                                    component="img"
                                    src='/flags/germany.png'
                                />
                            </Tooltip>

                            <Tooltip title="Italian">
                                <Box
                                    sx={{
                                        ...(selectedLanguage !== "italian" ? {
                                            filter: "saturate(0%)",
                                            WebkitFilter: "saturate(0%)"
                                        } : {}),
                                        ":hover": {
                                            filter: "saturate(100%)",
                                            WebkitFilter: "saturate(100%)",
                                            cursor: "pointer"
                                        }
                                    }}
                                    onClick={() => setSelectedLanguage("italian")}
                                    component="img"
                                    src='/flags/italy.png'
                                />
                            </Tooltip>

                            <Tooltip title="Portuguese">
                                <Box
                                    sx={{
                                        ...(selectedLanguage !== "portuguese" ? {
                                            filter: "saturate(0%)",
                                            WebkitFilter: "saturate(0%)"
                                        } : {}),
                                        ":hover": {
                                            filter: "saturate(100%)",
                                            WebkitFilter: "saturate(100%)",
                                            cursor: "pointer"
                                        }
                                    }}
                                    onClick={() => setSelectedLanguage("portuguese")}
                                    component="img"
                                    src='/flags/portugal.png'
                                />
                            </Tooltip>

                            <Tooltip title="Spanish">
                                <Box
                                    sx={{
                                        ...(selectedLanguage !== "spanish" ? {
                                            filter: "saturate(0%)",
                                            WebkitFilter: "saturate(0%)"
                                        } : {}),
                                        ":hover": {
                                            filter: "saturate(100%)",
                                            WebkitFilter: "saturate(100%)",
                                            cursor: "pointer"
                                        }
                                    }}
                                    onClick={() => setSelectedLanguage("spanish")}
                                    component="img"
                                    src='/flags/spain.png'
                                />
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
                <Box component="hr" my="20px" border={"1px solid " + colors.grey[500]} />
                <Box display="flex" justifyContent="space-between" textAlign="center" px="10px">
                    <Box>
                        <Typography variant='h5' fontWeight={600} sx={{ textDecoration: "underline" }} mb="15px">Category</Typography>
                        <Typography variant='h6'>
                            {category.name ? category.name : "-"}
                        </Typography>
                    </Box>
                    <Box  >
                        <Typography variant='h5' fontWeight={600} sx={{ textDecoration: "underline" }} mb="15px">Usage Limit</Typography>
                        <Typography variant='h6' >{subCategory.is_pro === 0 ? "Everyone" : "Premium Users"}</Typography>
                    </Box>
                    <Box>
                        <Box display="flex" mb="10px">
                            <Typography variant='h5' fontWeight={600} sx={{ textDecoration: "underline" }}>Position:</Typography>
                            <Typography variant='h6' ml="5px">{subCategory.position}</Typography>
                        </Box>

                        <Box display="flex" border="1px solid black" borderRadius="5px">
                            <IconButton sx={{ borderRadius: "0px", flexGrow: 1 }} onClick={() => { updateSubCategoryPosition(-1) }}>
                                <RemoveIcon />
                            </IconButton>

                            <IconButton sx={{ borderRadius: "0px", flexGrow: 1 }} onClick={() => { updateSubCategoryPosition(1) }}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default SubCategoryBox