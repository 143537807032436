import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../global/Header';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
// import ReadingCategoryDialog from './ReadingCategoryDialog';
// import ReadingCategoryBox from './ReadingCategoryBox';
import { AdminContext } from '../../context/AdminContext';
import CampaignDialog from './CampaignDialog';
import CampaignBox from './CampaignBox';

function CampaingsPage() {
    const { token } = useContext(AdminContext);
    const [searchKey, setSearchKey] = useState("");
    const [campaigns, setCampaigns] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [campaignToRemove, setCampaignToRemove] = useState(null);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [campaignDialogOpen, setCampaignDialogOpen] = useState(false);

    const fetchCampaigns = () => {
        axios.get(`${process.env.REACT_APP_API_URL}coupon/getCampaigns?searchKey=${searchKey}&startIndex=${startIndex}&limit=50&token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                setCampaigns([]);
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.campaigns && response.data.campaigns.length === 50);
                    if (startIndex === 0) {
                        setCampaigns(response.data.campaigns);
                    } else {
                        setCampaigns((prev) => [...prev, ...response.data.campaigns]);
                    }
                }
            });
    }



    useEffect(() => {
        fetchCampaigns();
    }, [startIndex]);

    const handleRemoveDialogClose = async () => {
        setCampaignToRemove(null);
        setRemoveDialogOpen(false);
    }

    const removeCoupons = async () => {
        await axios.post(`${process.env.REACT_APP_API_URL}coupon/deleteCouponsWithCampaignTitle?token=${token}`, {
            campaignTitle: campaignToRemove.campaign_title
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                if (startIndex === 0) {
                    fetchCampaigns();
                } else {
                    setStartIndex(0);
                }
                handleRemoveDialogClose();
            }
        });
    }

    useEffect(() => {
        if (campaignToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [campaignToRemove])

    return (
        <Box sx={{ p: "75px" }}>
            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{"Are you sure you want to delete the campaign (with releated coupons)?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You cannot undo this operation but you can add new campaign if you wish.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>Cancel</Button>
                    <Button onClick={() => removeCoupons()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <CampaignDialog dialogOpen={campaignDialogOpen} handleClose={(added) => {
                setCampaignDialogOpen(false);
                if (added) {
                    if (startIndex === 0) {
                        fetchCampaigns();
                    } else {
                        setStartIndex(0);
                    }
                }

            }} />
            <Box display="flex" alignItems="center" gap="20px">

                <Header title="Campaigns" subtitle="You can see and manage campaigns from this page." />

                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="search-box">Search</InputLabel>
                    <OutlinedInput
                        id="search-box"
                        type='text'
                        onChange={(e) => {
                            setSearchKey(e.target.value ? e.target.value : "");
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="search button"
                                    onClick={() => {
                                        setCampaigns([]);
                                        if (startIndex === 0) {
                                            fetchCampaigns();
                                        } else {
                                            setStartIndex(0);
                                        }
                                    }}
                                    edge="end"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Search"
                    />
                </FormControl>
                <Button variant='contained' color='success' sx={{ m: 1, width: '25ch' }} onClick={() => {
                    setCampaignDialogOpen(true);
                }} >
                    Add New Campaign
                </Button>
                <Button variant='contained' color='info' sx={{ m: 1, width: '25ch' }} onClick={() => {
                    window.location.href = "/campaigns/-1"
                }} >
                    Coupon usages
                </Button>
            </Box>
            <Box mt="30px">
                <InfiniteScroll
                    dataLength={campaigns.length}
                    next={() => {
                        setStartIndex(campaigns.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>You are viewing all categories.</b>
                        </p>
                    }
                >
                    <Box display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
                        {campaigns.map(campaign => <CampaignBox key={campaign.id} campaign={campaign} removeCampaign={(cmp) => setCampaignToRemove(cmp)} />)}
                    </Box>
                </InfiniteScroll>
            </Box>
        </Box>
    )
}

export default CampaingsPage
