import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';

function WordSetWordDialog({ targetWord, dialogOpen, handleClose, handleFormSubmit }) {

    var initialValues = {
        word: targetWord?.word ? targetWord?.word : "",
        translation: targetWord?.translation ? targetWord.translation : "",
        definition: targetWord?.definition ? targetWord.definition : "",
        definition_translation: targetWord?.definition_translation ? targetWord.definition_translation : "",
        example: targetWord?.example ? targetWord.example : "",
        example_translation: targetWord?.example_translation ? targetWord.example_translation : "",
        synonyms: targetWord?.synonyms ? targetWord.synonyms : "",
        synonyms_translation: targetWord?.synonyms_translation ? targetWord.synonyms_translation : "",
    }

    var validationObject = {
        word: yup.string().required("required"),
        translation: yup.string().required("required"),
        definition: yup.string().required("required"),
        definition_translation: yup.string().required("required"),
        example: yup.string().required("required"),
        example_translation: yup.string().required("required"),
        synonyms: yup.string().required("required"),
        synonyms_translation: yup.string().required("required"),
    }

    const wordSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">
                {targetWord &&
                    <Typography variant='h3'>
                        Edit Word
                    </Typography>
                }
                {!targetWord &&
                    <Typography variant='h3'>
                        New Word
                    </Typography>
                }
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={wordSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Word"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"word"}
                                        value={values.word}
                                        error={!!touched.word && !!errors.word}
                                        helperText={touched.word && errors.word}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Translation"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"translation"}
                                        value={values.translation}
                                        error={!!touched.translation && !!errors.translation}
                                        helperText={touched.translation && errors.translation}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Definition"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"definition"}
                                        value={values.definition}
                                        error={!!touched.definition && !!errors.definition}
                                        helperText={touched.definition && errors.definition}
                                        sx={{ gridColumn: "span 4", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Definition Translation"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"definition_translation"}
                                        value={values.definition_translation}
                                        error={!!touched.definition_translation && !!errors.definition_translation}
                                        helperText={touched.definition_translation && errors.definition_translation}
                                        sx={{ gridColumn: "span 4", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Example"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"example"}
                                        value={values.example}
                                        error={!!touched.example && !!errors.example}
                                        helperText={touched.example && errors.example}
                                        sx={{ gridColumn: "span 4", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Example Translation"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"example_translation"}
                                        value={values.example_translation}
                                        error={!!touched.example_translation && !!errors.example_translation}
                                        helperText={touched.example_translation && errors.example_translation}
                                        sx={{ gridColumn: "span 4", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Synonyms"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"synonyms"}
                                        value={values.synonyms}
                                        error={!!touched.synonyms && !!errors.synonyms}
                                        helperText={touched.synonyms && errors.synonyms}
                                        sx={{ gridColumn: "span 4", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Synonyms Translation"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"synonyms_translation"}
                                        value={values.synonyms_translation}
                                        error={!!touched.synonyms_translation && !!errors.synonyms_translation}
                                        helperText={touched.synonyms_translation && errors.synonyms_translation}
                                        sx={{ gridColumn: "span 4", marginTop: "20px" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default WordSetWordDialog