import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from '../../global/Header';
import axios from 'axios';
import UpdateAnnouncementBox from './UpdateAnnouncementBox';
import UpdateAnnouncementDialog from './UpdateAnnouncementDialog';

function UpdateAnnouncementsPage() {
    const [language, setLanguage] = useState("turkish");
    const [updateAnnouncements, setUpdateAnnouncements] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [targetUpdateAnnouncement, setTargetUpdateAnnouncement] = useState(null);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [updateAnnouncementToRemove, setUpdateAnnouncementToRemove] = useState(null);

    const fetchUpdateAnnouncements = () => {
        axios.get(`${process.env.REACT_APP_API_URL}new/getNewsWithLanguage?language=${language}`)
            .catch((err) => {
                console.log("err: " + err);
                setUpdateAnnouncements([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setUpdateAnnouncements(response.data.news);
                }
            });
    }

    useEffect(() => {
        fetchUpdateAnnouncements();
    }, [language]);

    const handleFormSubmit = async (values) => {
        if (values.photo) {
            await axios.post(`${process.env.REACT_APP_API_URL}new/uploadPhoto`, {
                photo: values.photo
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        values.photo_path = response.data.photoPath;
                    }
                });
        }
        delete values.photo;
        delete values.photo_link;
        if (targetUpdateAnnouncement) {
            await axios.patch(`${process.env.REACT_APP_API_URL}new/updateNew?newModelId=${targetUpdateAnnouncement.id}`, {
                newModel: values
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleClose();
                    fetchUpdateAnnouncements();
                }
            });
        } else {
            await axios.post(`${process.env.REACT_APP_API_URL}new/create`, {
                newModel: values
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleClose();
                    fetchUpdateAnnouncements();
                }
            });
        }
    }

    useEffect(() => {
        if (targetUpdateAnnouncement) {
            setDialogOpen(true);
        }
    }, [targetUpdateAnnouncement]);

    const handleClose = async () => {
        setDialogOpen(false);
        setTargetUpdateAnnouncement(null);
    }


    const updateUpdateAnnouncement = (updateAnnouncement) => {
        setTargetUpdateAnnouncement(updateAnnouncement);
    }

    useEffect(() => {
        if (updateAnnouncementToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [updateAnnouncementToRemove]);

    const handleRemoveDialogClose = async () => {
        setRemoveDialogOpen(false);
        setUpdateAnnouncementToRemove(null);
    }

    const handleUpdateAnnouncementToRemove = (updateAnnouncement) => {
        setUpdateAnnouncementToRemove(updateAnnouncement);
    }

    const removeUpdateAnnouncement = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}new/deleteNewViaId?newModelId=${updateAnnouncementToRemove.id}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                fetchUpdateAnnouncements();
                handleRemoveDialogClose();
            }
        });
    }

    return (
        <Box sx={{ p: "75px" }}>
            <UpdateAnnouncementDialog targetUpdateAnnouncement={targetUpdateAnnouncement} dialogOpen={dialogOpen} handleClose={handleClose} handleFormSubmit={handleFormSubmit} />
            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Are you sure you want to delete the update announcement?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You cannot undo this operation, you can add the same announcement again if you wish.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>Cancel</Button>
                    <Button onClick={() => removeUpdateAnnouncement()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <Box display="flex" alignItems="center" gap="20px">
                <Header title="Update Announcements" subtitle="You can see and manage update announcements from this page." />


                <Button variant='contained' color='success' sx={{ m: 1, width: '35ch' }} onClick={() => {
                    setDialogOpen(true);
                }} >
                    Add New Announcement
                </Button>

            </Box>
            <Box display="flex" alignItems="center" gap="20px">
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="language">Language</InputLabel>
                    <Select
                        id="language"
                        value={language}
                        label="Language"
                        onChange={(e) => {
                            setLanguage(e.target.value);
                        }}
                    >
                        <MenuItem value={"turkish"}>Turkish</MenuItem>
                        <MenuItem value={"french"}>French</MenuItem>
                        <MenuItem value={"german"}>German</MenuItem>
                        <MenuItem value={"spanish"}>Spanish</MenuItem>
                        <MenuItem value={"portuguese"}>Portuguese</MenuItem>
                        <MenuItem value={"italian"}>Italian</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box mt="30px">
                <Box display="flex" flexWrap="wrap" gap="20px">
                    {updateAnnouncements.map(updateAnnouncement => <UpdateAnnouncementBox key={updateAnnouncement.id} updateAnnouncement={updateAnnouncement} updateUpdateAnnouncement={updateUpdateAnnouncement} removeUpdateAnnouncement={handleUpdateAnnouncementToRemove} />)}
                </Box>
            </Box>
        </Box>
    )
}

export default UpdateAnnouncementsPage
