import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../global/Header'
import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, Menu, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import axios from 'axios';
import { Formik } from 'formik';
import * as yup from "yup";
import SentenceWordDialog from './SentenceWordDialog';
import SentenceWordBox from './SentenceWordBox';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { AdminContext } from '../../../context/AdminContext';
import { tokens } from '../../../theme';
import { useParams } from 'react-router-dom';

function ReadingAddPage() {
    const colors = tokens();
    const { token } = useContext(AdminContext);

    const { readingId, editType } = useParams();
    const [reading, setReading] = useState(null);
    const [step, setStep] = useState(0);
    const [categories, setCategories] = useState([]);
    const [examCategories, setExamCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [sentenceCount, setSentenceCount] = useState(0);
    const [targetWordState, setTargetWordState] = useState({
        targetWord: null,
        choosenSentenceIndex: -1,
        toRemove: false
    });
    const [sentenceTranslationCount, setSentenceTranslationCount] = useState(0);
    const [wordDialogOpen, setWordDialogOpen] = useState(false);
    const [wordRemoveDialogOpen, setWordRemoveDialogOpen] = useState(false);

    var initialValues = {
        ...(!editType || editType === "details" ? {
            title: reading ? reading.title : "",
            thumbnail: null,
            thumbnail_link: reading?.thumbnail_link ? reading?.thumbnail_link : "",
            difficulty: reading ? reading.difficulty : 0,
            is_pro: reading ? reading.is_pro : 0,
            reading_exam_category_id: reading ? reading.reading_exam_category_id : "",
            level_category: reading ? reading.level_category : "",
            categories: reading ? reading.categories : []
        } : {}),
        ...(readingId ? {} : {
            content: ""
        }),
        ...(!editType || editType === "sentences" ? { sentences: reading ? reading.sentences : [] } : {}),
        ...(!editType || editType === "questions" ? {
            questions: reading ? reading.questions : []
        } : {})
    }

    var validationObject = {
        ...(!editType || editType === "details" ? {
            title: yup.string().required("required"),
            thumbnail: yup.mixed().nullable(),
            thumbnail_link: yup.string(),
            difficulty: yup.number().required("required"),
            is_pro: yup.number().required("required"),
            reading_exam_category_id: yup.number().nullable(),
            level_category: yup.string().nullable(),
            categories: yup.array().of(
                yup.object({
                    id: yup.number().required("required"),
                    title: yup.string().required("required")
                })
            )
        } : {}),
        ...(readingId ? {} : {
            content: yup.string().required("required")
        }),
        ...(!editType || editType === "sentences" ? {
            sentences: yup.array().of(
                yup.object({
                    ...(editType === "sentences" ? { id: yup.string().required("required") } : {}),
                    sentence: yup.string().required("required"),
                    words: yup.array().of(
                        yup.object({
                            word: yup.string().required("required"),
                            highlight: yup.string().required("required"),
                            type: yup.string().required("required"),
                            definition: yup.string().required("required"),
                            translations: yup.array().of(
                                yup.object({
                                    translation: yup.string().required("required"),
                                    language: yup.string().required("required")
                                })
                            )
                        })
                    ),
                    translations: yup.array().of(
                        yup.object({
                            translation: yup.string().required("required"),
                            language: yup.string().required("required")
                        })
                    )
                })
            )
        } : {}),
        ...(!editType || editType === "questions" ? {
            questions: yup.array().of(
                yup.object({
                    ...(editType === "questions" ? { id: yup.string().required("required") } : {}),
                    question: yup.string().required("required"),
                    a: yup.string().required("required"),
                    b: yup.string().required("required"),
                    c: yup.string().required("required"),
                    d: yup.string().required("required"),
                    answer: yup.string().required("required")
                })
            )
        } : {})
    }

    const readingSchema = yup.object().shape(validationObject);

    const performSentenceTranslate = async (sentence, i, handledSentences, setFieldValue, order, language, languageSymbol) => {
        try {
            var response = await axios.patch(`${process.env.REACT_APP_API_URL}translateWithBing`, {
                sentence: sentence.sentence,
                languageSymbol: languageSymbol
            })
            if (response && response.data.success === true && response.data.status === 200) {
                // setFieldValue("sentences[" + i + "].translations[" + order + "]", { translation: response.data.translation, language: language });
                sentence.translations[order] = { translation: response.data.translation, language: language }
            } else {
                // setFieldValue("sentences[" + i + "].translations[" + order + "]", { translation: "-", language: language });
                sentence.translations[order] = { translation: "-", language: language }
            }
        } catch (error) {
            console.log(error);
        }
        finally {
            if (i === handledSentences.length - 1) {
                setSentenceTranslationCount((prev) => {
                    if (prev === 5) {
                        setFieldValue("sentences", handledSentences);
                        setStep(1);
                    }
                    return prev + 1
                })
            }
        }

    }

    const handleFormSubmit = async (values, setFieldValue) => {
        if (readingId) {
            if (editType === "details") {
                updateReading(values)
            } else if (editType === "questions") {
                updateReadingQuestions(values)
            } else {
                if (sentenceCount + 5 >= values.sentences.length) {
                    updateReadingSentences(values)
                } else {
                    setSentenceCount((prev) => prev + 5);
                    window.scrollTo(0, 0);
                }
            }
        } else {
            if (step === 0) {
                setIsLoading(true);
                var tempSentences = values.content.replace(/([.?!])\s*(?=[A-Z])/g, "$1|").split("|");
                var handledSentences = [];
                tempSentences.forEach(async (sentence) => {
                    handledSentences.push({
                        sentence: sentence,
                        words: [],
                        translations: [
                            {
                                translation: "-",
                                language: "turkish"
                            },
                            {
                                translation: "-",
                                language: "german"
                            },
                            {
                                translation: "-",
                                language: "spanish"
                            },
                            {
                                translation: "-",
                                language: "french"
                            },
                            {
                                translation: "-",
                                language: "portuguese"
                            },
                            {
                                translation: "-",
                                language: "italian"
                            }
                        ]
                    })
                });
                for (let i = 0; i < handledSentences.length; i++) {
                    await performSentenceTranslate(handledSentences[i], i, handledSentences, setFieldValue, 0, "turkish", "tr");
                    await performSentenceTranslate(handledSentences[i], i, handledSentences, setFieldValue, 1, "german", "de");
                    await performSentenceTranslate(handledSentences[i], i, handledSentences, setFieldValue, 2, "spanish", "es");
                    await performSentenceTranslate(handledSentences[i], i, handledSentences, setFieldValue, 3, "french", "fr");
                    await performSentenceTranslate(handledSentences[i], i, handledSentences, setFieldValue, 4, "portuguese", "pt-PT");
                    await performSentenceTranslate(handledSentences[i], i, handledSentences, setFieldValue, 5, "italian", "it");
                }
            } else if (step === 1) {
                var step2Check = true;

                if (values.sentences.slice(0, 5).length > 0) {
                    for (let i = 0; i < values.sentences.slice(sentenceCount, sentenceCount + 5).length; i++) {
                        const sentence = values.sentences.slice(sentenceCount, sentenceCount + 5)[i];
                        if (sentence.translations?.length > 0) {
                            for (let j = 0; j < sentence.translations.length; j++) {
                                const translation = sentence.translations[j];
                                if (!translation || !translation.translation || translation.translation === "" || translation.translation === "-") {

                                    step2Check = false;
                                    break;
                                }
                            }
                        } else {
                            step2Check = false;
                            break;
                        }
                    }
                } else {
                    step2Check = false;
                }

                if (step2Check) {
                    if (sentenceCount + 5 >= values.sentences.length) {
                        setStep(2);
                    } else {
                        setSentenceCount((prev) => prev + 5);
                        window.scrollTo(0, 0);
                    }
                } else {
                    setAlertText("All translations should be added.");
                    setOpenSnackbar(true);
                }
            } else if (step === 2) {
                if (values?.questions?.length > 0) {
                    addReading(values);
                } else {
                    setAlertText("At least 1 question should be added.");
                    setOpenSnackbar(true);
                }

            }
        }

    }

    useEffect(() => {
        setIsLoading(false);
    }, [step])

    useEffect(() => {
        if (readingId && editType) {
            setIsLoading(true);
            if (editType === "sentences") {
                axios.get(`${process.env.REACT_APP_API_URL}reading/getReadingSentencesToUpdate?token=${token}&readingId=${readingId}`)
                    .catch((err) => {
                        console.log("err: " + err);
                    })
                    .then((response) => {
                        if (response && response.data.success === true && response.data.status === 200) {
                            setStep(1);
                            setReading({
                                sentences: response.data.sentences.map((s) => ({ id: s.id, sentence: s.sentence, translations: s.translations, words: s.words }))
                            })
                            setIsLoading(false);
                        }
                    });
            } else {
                axios.get(`${process.env.REACT_APP_API_URL}reading/getReadingToUpdate?token=${token}&readingId=${readingId}`)
                    .catch((err) => {
                        console.log("err: " + err);
                    })
                    .then((response) => {
                        if (response && response.data.success === true && response.data.status === 200) {
                            if (editType === "details") {
                                setReading({
                                    title: response.data.reading.title,
                                    thumbnail_link: response.data.reading.thumbnail_link,
                                    difficulty: response.data.reading.difficulty,
                                    is_pro: response.data.reading.is_pro,
                                    reading_exam_category_id: response.data.reading.reading_exam_category_id,
                                    level_category: response.data.reading.level_category,
                                    categories: response.data.reading.categories.map((ct) => ({ id: ct.id, title: ct.title }))
                                })
                            } else if (editType === "questions") {
                                setStep(2);
                                setReading({
                                    content: response.data.reading.content,
                                    questions: response.data.reading.questions.map((q) => ({ id: q.id, question: q.question, a: q.a, b: q.b, c: q.c, d: q.d, answer: q.answer }))
                                })
                            }
                            setIsLoading(false);
                        }
                    });
            }
        }
        fetchCategories();
        fetchExamCategories();
    }, [])

    useEffect(() => {
        if (targetWordState.toRemove) {
            setWordRemoveDialogOpen(targetWordState?.choosenSentenceIndex !== -1 && !!targetWordState.targetWord)
        } else {
            setWordDialogOpen(targetWordState?.choosenSentenceIndex !== -1);
        }
    }, [targetWordState])

    const removeWord = (values, setFieldValue) => {
        if (targetWordState?.choosenSentenceIndex !== -1 && targetWordState.targetWord && targetWordState.toRemove) {
            let wordIx = values.sentences[targetWordState.choosenSentenceIndex].words.findIndex((w) => w.word === targetWordState.targetWord.word && w.definition === targetWordState.targetWord.definition);
            let handledWords = [...values.sentences[targetWordState.choosenSentenceIndex].words];
            handledWords.splice(wordIx, 1)
            setFieldValue(`sentences[${targetWordState.choosenSentenceIndex}].words`, handledWords);
            setTargetWordState({ targetWord: null, choosenSentenceIndex: -1, toRemove: true })
        }
    }


    const addReading = async (values) => {
        setIsLoading(true);
        if (values.thumbnail) {
            await axios.post(`${process.env.REACT_APP_API_URL}reading/uploadThumbnail?token=${token}`, {
                thumbnail: values.thumbnail
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        values.thumbnail_path = response.data.thumbnailPath;
                    }
                });
        }
        delete values.thumbnail;
        delete values.thumbnail_link;
        await axios.post(`${process.env.REACT_APP_API_URL}reading/?token=${token}`, {
            ...values
        }).catch((err) => {
            console.log("err: " + err);
            setIsLoading(false);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                setIsLoading(false);
                window.open(`${process.env.REACT_APP_WEB_URL}readings`, '_self');
            }
        });
    }

    const updateReading = async (values) => {
        setIsLoading(true);
        if (values.thumbnail) {
            await axios.post(`${process.env.REACT_APP_API_URL}reading/uploadThumbnail?token=${token}`, {
                thumbnail: values.thumbnail
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        values.thumbnail_path = response.data.thumbnailPath;
                    }
                });
        }

        delete values.thumbnail;
        delete values.thumbnail_link;

        await axios.patch(`${process.env.REACT_APP_API_URL}reading/updateReading?token=${token}&readingId=${readingId}`, {
            ...values
        }).catch((err) => {
            console.log("err: " + err);
            setIsLoading(false);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                setIsLoading(false);
                window.open(`${process.env.REACT_APP_WEB_URL}readings`, '_self');
            }
        });
    }

    const updateReadingQuestions = async (values) => {
        setIsLoading(true);
        await axios.patch(`${process.env.REACT_APP_API_URL}reading/updateReadingQuestions?token=${token}&readingId=${readingId}`, {
            ...values
        }).catch((err) => {
            console.log("err: " + err);
            setIsLoading(false);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                setIsLoading(false);
                window.open(`${process.env.REACT_APP_WEB_URL}readings`, '_self');
            }
        });
    }

    const updateReadingSentences = async (values) => {
        setIsLoading(true);
        await axios.patch(`${process.env.REACT_APP_API_URL}reading/updateReadingSentences?token=${token}&readingId=${readingId}`, {
            ...values
        }).catch((err) => {
            console.log("err: " + err);
            setIsLoading(false);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                setIsLoading(false);
                window.open(`${process.env.REACT_APP_WEB_URL}readings`, '_self');
            }
        });
    }

    const fetchCategories = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}reading/getReadingCategories?token=${token}`).catch((err) => {
            console.log("err: " + err);
            setCategories([])
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                setCategories(response.data.categories);
            }
        });
    }

    const fetchExamCategories = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}reading/getReadingExamCategories?token=${token}`).catch((err) => {
            console.log("err: " + err);
            setExamCategories([])
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                setExamCategories(response.data.categories);
            }
        });
    }


    return (
        <Box sx={{ p: "75px" }}>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="warning"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {alertText}
                </Alert>
            </Snackbar>
            <Box display="flex" alignItems="center" gap="20px">
                <Header title={editType ? `Edit ${editType}` : "New Reading"} subtitle="" />
            </Box>
            <Box>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={readingSchema}
                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <Box>
                                {isLoading &&
                                    <Box display="flex" justifyContent="center">
                                        <CircularProgress variant='indeterminate' />
                                    </Box>
                                }
                                {!isLoading && step === 0 && (!editType || editType === "details") &&
                                    <Box display="grid" gridTemplateColumns={"repeat(6, 1fr)"} alignItems="center" gap="15px">
                                        <TextField
                                            variant="filled"
                                            type="text"
                                            label="Title"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"title"}
                                            value={values.title}
                                            error={!!touched.title && !!errors.title}
                                            helperText={touched.title && errors.title}
                                        />
                                        <FormControl variant="filled">
                                            <InputLabel id="filter-label1">Difficulty</InputLabel>
                                            <Select
                                                labelId="filter-label1"
                                                label="Difficulty"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"difficulty"}
                                                value={values.difficulty}
                                                error={!!touched.difficulty && !!errors.difficulty}
                                            >
                                                <MenuItem value={0}>Easy</MenuItem>
                                                <MenuItem value={1}>Medium</MenuItem>
                                                <MenuItem value={2}>Hard</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl variant="filled">
                                            <InputLabel id="filter-label2">Status</InputLabel>
                                            <Select
                                                labelId="filter-label2"
                                                label="Status"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"is_pro"}
                                                value={values.is_pro}
                                                error={!!touched.is_pro && !!errors.is_pro}
                                            >
                                                <MenuItem value={0}>Free</MenuItem>
                                                <MenuItem value={1}>Pro</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl variant="filled">
                                            <InputLabel id="filter-label3">Exam Category</InputLabel>
                                            <Select
                                                labelId="filter-label3"
                                                label="Exam Category"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"reading_exam_category_id"}
                                                value={values.reading_exam_category_id}
                                                error={!!touched.reading_exam_category_id && !!errors.reading_exam_category_id}
                                            >
                                                <MenuItem value={""}>NONE</MenuItem>
                                                {examCategories.map(({ id, title }) => (
                                                    <MenuItem key={id} value={id}>{title}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl variant="filled" >
                                            <InputLabel id="filter-label4">Level Category</InputLabel>
                                            <Select
                                                labelId="filter-label4"
                                                label="Level Category"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"level_category"}
                                                value={values.level_category}
                                                error={!!touched.level_category && !!errors.level_category}
                                            >
                                                <MenuItem value={""}>NONE</MenuItem>
                                                <MenuItem value={"a1"}>A1</MenuItem>
                                                <MenuItem value={"a2"}>A2</MenuItem>
                                                <MenuItem value={"b1"}>B1</MenuItem>
                                                <MenuItem value={"b2"}>B2</MenuItem>
                                                <MenuItem value={"c1"}>C1</MenuItem>
                                                <MenuItem value={"c2"}>C2</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl variant="filled" >
                                            <InputLabel id="filter-label5">Categories</InputLabel>
                                            <Select
                                                labelId="filter-label5"
                                                label="Categories"
                                                onBlur={handleBlur}
                                                onChange={(event) => {
                                                    const {
                                                        target: { value },
                                                    } = event;
                                                    var lastValue = value[value.length - 1];
                                                    var existingIndex = value.findIndex((e) => e.id === lastValue.id);
                                                    if (existingIndex !== -1 && existingIndex !== value.length - 1) {
                                                        value.splice(value.length - 1, 1);
                                                        value.splice(existingIndex, 1);
                                                    }
                                                    setFieldValue("categories", typeof value === 'string' ? value.split(',') : value)

                                                }}
                                                multiple
                                                name={"categories"}
                                                value={values.categories}
                                                // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={value.id} label={value.title} />
                                                        ))}
                                                    </Box>
                                                )}
                                            >
                                                {categories.map(({ id, title }) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={{ id: id, title: title }}
                                                    >
                                                        {title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Box textAlign="center" sx={{ gridColumn: "span 1", mt: "10px" }}>
                                            <Typography variant="h6">Thumbnail Image</Typography>
                                            <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                                {(values.thumbnail || (values.thumbnail_link && values.thumbnail_link !== "")) && <img alt='square brand logo' width="140px" src={values.thumbnail ? URL.createObjectURL(values.thumbnail) : values.thumbnail_link} />}
                                                <input id="thumbnail" name="thumbnail" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("thumbnail", event.target.files[0]); }} hidden />
                                            </Button>
                                        </Box>
                                        {!readingId &&
                                            <TextField
                                                variant="filled"
                                                type="text"
                                                multiline
                                                minRows={5}
                                                label="Text"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"content"}
                                                value={values.content}
                                                error={!!touched.content && !!errors.content}
                                                helperText={touched.content && errors.content}
                                                sx={{ gridColumn: "span 5", mt: "25px" }}
                                            />
                                        }
                                    </Box>
                                }
                                {!isLoading && step === 1 &&
                                    <Box display="grid" gridTemplateColumns={"repeat(5, 1fr)"} alignItems="center" gap="15px">
                                        <SentenceWordDialog targetWord={targetWordState.targetWord} choosenSentenceIndex={targetWordState.choosenSentenceIndex} parentFormValues={values} parentFormSetFieldValue={setFieldValue} dialogOpen={wordDialogOpen} handleClose={() => { setTargetWordState({ targetWord: null, choosenSentenceIndex: -1, toRemove: false }) }} />
                                        <Dialog
                                            open={wordRemoveDialogOpen}
                                            keepMounted
                                            onClose={() => setTargetWordState({ targetWord: null, choosenSentenceIndex: -1, toRemove: true })}
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle>{"Are you sure you want to delete the word?"}</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    You cannot undo this operation but you can add new word if you wish.
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => setTargetWordState({ targetWord: null, choosenSentenceIndex: -1, toRemove: true })}>Cancel</Button>
                                                <Button onClick={() => removeWord(values, setFieldValue)}>Delete</Button>
                                            </DialogActions>
                                        </Dialog>
                                        {values.sentences.map((sentence, i) => {
                                            if (i < sentenceCount + 5 && i >= sentenceCount)
                                                return (
                                                    <Box display="grid" gridTemplateColumns={"repeat(5, 1fr)"} alignItems="center" gap="15px" key={`sentence-${i}`} sx={{ gridColumn: "span 5", mb: "50px" }}>
                                                        {"Sentence - " + i}
                                                        <TextField
                                                            variant="filled"
                                                            type="text"
                                                            label="Sentence"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            name={"sentences[" + i + "].sentence"}
                                                            value={values.sentences[i].sentence}
                                                            disabled={!!readingId}
                                                            // error={!!touched.sentences && !!touched.sentences[i] && !!touched.sentences[i].sentence && !!errors.sentences && !!errors.sentences[i] && !!errors.sentences[i].sentence}
                                                            // helperText={touched.sentences && touched.sentences[i] && touched.sentences[i].sentence && errors.sentences && errors.sentences[i] && errors.sentences[i].sentence}
                                                            sx={{ gridColumn: "span 5" }}
                                                        />
                                                        {sentence.translations.map((translation, j) =>
                                                            <TextField
                                                                key={`translation-${j}`}
                                                                variant="filled"
                                                                type="text"
                                                                label={`Sentence(${translation.language})`}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                name={"sentences[" + i + "].translations[" + j + "].translation"}
                                                                value={values.sentences[i].translations[j].translation}
                                                                // error={!!touched?.sentences[i]?.translations && !!touched?.sentences[i]?.translations[j] && !!touched?.sentences[i]?.translations[j]?.translation && !!errors?.sentences[i]?.translations && !!errors?.sentences[i]?.translations[j] && !!errors?.sentences[i]?.translations[j]?.translation}
                                                                // helperText={touched?.sentences[i]?.translations && touched?.sentences[i]?.translations[j] && touched?.sentences[i]?.translations[j]?.translation && errors?.sentences[i]?.translations && errors?.sentences[i]?.translations[j] && errors?.sentences[i]?.translations[j]?.translation}
                                                                sx={{ gridColumn: "span 5" }}
                                                            />
                                                        )}
                                                        <Box display="grid" gridTemplateColumns={"repeat(3, 1fr)"} alignItems="center" gap={"20px"} sx={{ gridColumn: "span 5" }}>
                                                            {sentence.words.map((word, j) =>
                                                                <SentenceWordBox key={"sentenceWord" + j} word={word} openEditDialog={() => { setTargetWordState((prev) => ({ targetWord: word, choosenSentenceIndex: i, toRemove: false })) }} openRemoveDialog={() => { setTargetWordState((prev) => ({ targetWord: word, choosenSentenceIndex: i, toRemove: true })) }} />
                                                            )}
                                                        </Box>
                                                        <Button variant='contained' color='success' onClick={() => {
                                                            setTargetWordState((prev) => ({ targetWord: null, choosenSentenceIndex: i, toRemove: false }))
                                                        }}>Add Word</Button>
                                                    </Box>
                                                )
                                        }
                                        )}
                                    </Box>
                                }
                                {!isLoading && step === 2 &&
                                    <Box display="grid" gridTemplateColumns={"repeat(5, 1fr)"} alignItems="center" gap="15px">
                                        {!!reading &&
                                            <Typography sx={{ gridColumn: "span 5", mb: "30px" }}>{reading.content}</Typography>
                                        }
                                        {values.questions.map((q, i) =>
                                            <Box display="grid" gridTemplateColumns={"repeat(5, 1fr)"} alignItems="center" gap="15px" key={`question-${i}`} sx={{ gridColumn: "span 5", mb: "50px" }}>
                                                <TextField
                                                    variant="filled"
                                                    type="text"
                                                    label="Question"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    name={"questions[" + i + "].question"}
                                                    value={values.questions[i].question}
                                                    sx={{ gridColumn: "span 4" }}
                                                />
                                                <FormControl variant="filled" sx={{ gridColumn: "span 1" }}>
                                                    <InputLabel id="filter-label">Answer</InputLabel>
                                                    <Select
                                                        labelId="filter-label"
                                                        label="Answer"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        name={"questions[" + i + "].answer"}
                                                        value={values.questions[i].answer}
                                                    >
                                                        <MenuItem value={"a"}>Option A</MenuItem>
                                                        <MenuItem value={"b"}>Option B</MenuItem>
                                                        <MenuItem value={"c"}>Option C</MenuItem>
                                                        <MenuItem value={"d"}>Option D</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <TextField
                                                    variant="filled"
                                                    type="text"
                                                    label="Option A"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    name={"questions[" + i + "].a"}
                                                    value={values.questions[i].a}
                                                    sx={{ gridColumn: "span 1" }}
                                                />
                                                <TextField
                                                    variant="filled"
                                                    type="text"
                                                    label="Option B"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    name={"questions[" + i + "].b"}
                                                    value={values.questions[i].b}
                                                    sx={{ gridColumn: "span 1" }}
                                                />
                                                <TextField
                                                    variant="filled"
                                                    type="text"
                                                    label="Option C"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    name={"questions[" + i + "].c"}
                                                    value={values.questions[i].c}
                                                    sx={{ gridColumn: "span 1" }}
                                                />
                                                <TextField
                                                    variant="filled"
                                                    type="text"
                                                    label="Option D"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    name={"questions[" + i + "].d"}
                                                    value={values.questions[i].d}
                                                    sx={{ gridColumn: "span 1" }}
                                                />

                                                <Button variant='contained' color='error' sx={{ gridColumn: "span 1" }} onClick={() => {
                                                    let tempQuestions = [...values.questions];
                                                    tempQuestions.splice(i, 1);
                                                    setFieldValue("questions", tempQuestions)
                                                }}>Remove</Button>
                                            </Box>
                                        )}
                                        <Button variant='contained' color='success' onClick={() => {
                                            setFieldValue(`questions[${values.questions.length}]`, {
                                                ...(editType && editType === "questions" ? { id: -1 } : {}),
                                                question: "",
                                                a: "",
                                                b: "",
                                                c: "",
                                                d: "",
                                                answer: ""
                                            })
                                        }}>Add Question</Button>
                                    </Box>
                                }
                                {!isLoading && ((sentenceCount - 5) >= 0) &&
                                    <Button onClick={() => {
                                        if (step === 1 && sentenceCount - 5 >= 0) {
                                            setSentenceCount((prev) => prev - 5);
                                            window.scrollTo(0, 0);
                                        } else if (step === 2) {
                                            setStep((prev) => prev - 1);
                                            window.scrollTo(0, 0);
                                        }
                                    }} color="success" variant="contained" sx={{ position: "sticky", bottom: "10px", left: "10%", height: "100px", width: "100px", mt: "50px", borderRadius: "50px" }} >
                                        BACK
                                    </Button>}

                                {!isLoading &&
                                    <Button type="submit" onClick={() => {
                                        if (Object.keys(errors).length === 0) {
                                            handleFormSubmit(values, setFieldValue)
                                        } else {
                                            console.log(errors);
                                        }
                                    }} color="success" variant="contained" sx={{ position: "sticky", bottom: "10px", left: "90%", height: "100px", width: "100px", mt: "50px", borderRadius: "50px" }} >
                                        {readingId ? (editType === "sentences" && (sentenceCount + 5) < values.sentences.length ? "NEXT" : "UPDATE") : "NEXT"}
                                    </Button>}
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Box >
    )
}

export default ReadingAddPage