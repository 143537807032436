import React, { useEffect, useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import axios from 'axios';
import { Formik } from 'formik';
import * as yup from "yup";
import Header from '../../global/Header';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function GoldSettingsPage() {
    const [goldCountList, setGoldCountList] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [alert, setAlert] = useState(null);

    const fetchGoldSettings = () => {
        axios.get(`${process.env.REACT_APP_API_URL}settings/getGoldSettings`)
            .catch((err) => {
                setGoldCountList([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setGoldCountList(response.data.goldCountList);
                }
            });
    }

    useEffect(() => {
        fetchGoldSettings();
    }, [])

    useEffect(() => {
        if (alert) {
            setOpenSnackbar(true);
        } else {
            setOpenSnackbar(false);
        }
    }, [alert])

    if (goldCountList && goldCountList.length > 0) {
        var goldCountListInitialValues = {
            gold_counts: [...goldCountList]
        }
        var goldCountListValidationObject = {
            gold_counts: yup.array().of(
                yup.number().min(1, "Can be at least 0").required("required")
            ).required("required")
        }

        const goldCountListSchema = yup.object().shape(goldCountListValidationObject);


        const updateGoldCountList = async (values) => {
            await axios.patch(`${process.env.REACT_APP_API_URL}settings/updateGoldCountList`, {
                goldCountList: values.gold_counts
            }).catch((err) => {
                console.log("err: " + err);
                setAlert({ text: "An error occurred while updating gold settings", type: "error" });
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    fetchGoldSettings();
                    setAlert({ text: "Gold settings updated successfully", type: "success" });
                }
            });
        }

        return (
            <Box sx={{ p: "75px" }}>
                {alert &&
                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                        <Alert
                            onClose={() => { setAlert(null) }}
                            severity={alert.type}
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {alert.text}
                        </Alert>
                    </Snackbar>
                }
                <Box display="flex" alignItems="center" gap="20px">
                    <Header title="Gold Settings" subtitle="You can see and manage gold settings from this page" />
                </Box>
                <Box mt="30px">
                    <Box display="flex" flexWrap="wrap" gap="20px">
                        <Formik
                            onSubmit={updateGoldCountList}
                            initialValues={goldCountListInitialValues}
                            validationSchema={goldCountListSchema}
                        >
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box display="flex" flexWrap="wrap" alignItems="center" gap="15px">
                                        {goldCountList.map((goldCount, i) =>
                                            <TextField
                                                key={"gold" + i}
                                                variant="filled"
                                                type="number"
                                                label={(i + 1) + ". user"}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"gold_counts[" + i + "]"}
                                                value={values.gold_counts[i]}
                                                error={!!touched.gold_counts && !!touched.gold_counts[i] && !!errors.gold_counts && !!errors.gold_counts[i]}
                                                helperText={touched.gold_counts && touched.gold_counts[i] && errors.gold_counts && errors.gold_counts[i]}
                                                sx={{ minWidth: "200px" }}
                                            />
                                        )}
                                    </Box>
                                    <Box mt="30px" mr="30px" display="flex" justifyContent="end">
                                        <Button type="submit" color="success" variant="contained" >
                                            Save
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Box >
        )
    }


}

export default GoldSettingsPage