import React, { useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';

const languagePrefixes = {
    turkish: "tr",
    french: "fr",
    german: "de",
    italian: "it",
    portuguese: "pt",
    spanish: "sp"
}


function LinkSettingsDialog({ dialogOpen, handleClose, settings, fetchSettings }) {

    var initialValues = {
        about_link: settings && settings[languagePrefixes["turkish"] + "_about_link"] ? settings[languagePrefixes["turkish"] + "_about_link"] : "",
        help_link: settings && settings[languagePrefixes["turkish"] + "_help_link"] ? settings[languagePrefixes["turkish"] + "_help_link"] : "",
        main_page_word: settings && settings[languagePrefixes["turkish"] + "_main_page_word"] ? settings[languagePrefixes["turkish"] + "_main_page_word"] : "",
        language: "turkish"
    }

    var validationObject = {
        about_link: yup.string().required("required"),
        help_link: yup.string().required("required"),
        main_page_word: yup.string().required("required"),
        language: yup.string().required("required")
    }

    const settingsSchema = yup.object().shape(validationObject);

    const handleFormSubmit = async (values) => {
        await axios.patch(`${process.env.REACT_APP_API_URL}settings/updateLinks`, {
            ...values
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                fetchSettings();
                handleClose();
            }
        });
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" mx="150px" textAlign="center">
                <Typography variant='h3'>
                    Link Settings
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={settingsSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mb="25px">
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2", marginTop: "10px" }}>
                                        <InputLabel id="filter-label">Language</InputLabel>
                                        <Select
                                            labelId="filter-label"
                                            label="Language"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("language", e.target.value);
                                                setFieldValue("help_link", settings[languagePrefixes[e.target.value] + "_help_link"]);
                                                setFieldValue("about_link", settings[languagePrefixes[e.target.value] + "_about_link"]);
                                                setFieldValue("main_page_word", settings[languagePrefixes[e.target.value] + "_main_page_word"]);
                                            }}
                                            name={"language"}
                                            value={values.language}
                                            error={!!touched.language && !!errors.language}
                                        >
                                            <MenuItem value={"turkish"}>Turkish</MenuItem>
                                            <MenuItem value={"french"}>French</MenuItem>
                                            <MenuItem value={"german"}>German</MenuItem>
                                            <MenuItem value={"spanish"}>Spanish</MenuItem>
                                            <MenuItem value={"portuguese"}>Portuguese</MenuItem>
                                            <MenuItem value={"italian"}>Italian</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Help Link"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"help_link"}
                                        value={values.help_link}
                                        error={!!touched.help_link && !!errors.help_link}
                                        helperText={touched.help_link && errors.help_link}
                                        sx={{ gridColumn: "span 1", marginTop: "10px" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="About Link"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"about_link"}
                                        value={values.about_link}
                                        error={!!touched.about_link && !!errors.about_link}
                                        helperText={touched.about_link && errors.about_link}
                                        sx={{ gridColumn: "span 1", marginTop: "10px" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Word on Main Page"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"main_page_word"}
                                        value={values.main_page_word}
                                        error={!!touched.main_page_word && !!errors.main_page_word}
                                        helperText={touched.main_page_word && errors.main_page_word}
                                        sx={{ gridColumn: "span 1", marginTop: "10px" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default LinkSettingsDialog