import React, { useEffect, useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

function AdminDialog({ targetAdmin, dialogOpen, handleClose, handleFormSubmit }) {

    var initialValues = {
        username: targetAdmin?.username ? targetAdmin?.username : "",
        ...(!targetAdmin ? { password: "" } : {}),
        users_perm: targetAdmin?.users_perm !== undefined && targetAdmin?.users_perm !== null ? targetAdmin?.users_perm : "",
        words_perm: targetAdmin?.words_perm !== undefined && targetAdmin?.words_perm !== null ? targetAdmin?.words_perm : "",
        questions_perm: targetAdmin?.questions_perm !== undefined && targetAdmin?.questions_perm !== null ? targetAdmin?.questions_perm : "",
        wordsets_perm: targetAdmin?.wordsets_perm !== undefined && targetAdmin?.wordsets_perm !== null ? targetAdmin?.wordsets_perm : "",
        announcements_perm: targetAdmin?.announcements_perm !== undefined && targetAdmin?.announcements_perm !== null ? targetAdmin?.announcements_perm : "",
        update_announcements_perm: targetAdmin?.update_announcements_perm !== undefined && targetAdmin?.update_announcements_perm !== null ? targetAdmin?.update_announcements_perm : "",
        notifications_perm: targetAdmin?.notifications_perm !== undefined && targetAdmin?.notifications_perm !== null ? targetAdmin?.notifications_perm : "",
        settings_perm: targetAdmin?.settings_perm !== undefined && targetAdmin?.settings_perm !== null ? targetAdmin?.settings_perm : "",
        admins_perm: targetAdmin?.admins_perm !== undefined && targetAdmin?.admins_perm !== null ? targetAdmin?.admins_perm : "",
    }

    var validationObject = {
        username: yup.string().required("required"),
        ...(!targetAdmin ? { password: yup.string().required("required") } : {}),
        users_perm: yup.number().min(0, "Please Select").required("required"),
        words_perm: yup.number().min(0, "Please Select").required("required"),
        questions_perm: yup.number().min(0, "Please Select").required("required"),
        wordsets_perm: yup.number().min(0, "Please Select").required("required"),
        announcements_perm: yup.number().min(0, "Please Select").required("required"),
        update_announcements_perm: yup.number().min(0, "Please Select").required("required"),
        notifications_perm: yup.number().min(0, "Please Select").required("required"),
        settings_perm: yup.number().min(0, "Please Select").required("required"),
        admins_perm: yup.number().min(0, "Please Select").required("required")
    }

    const adminSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" mx="150px" textAlign="center">
                {targetAdmin &&
                    <Typography variant='h3'>
                        Edit Admin
                    </Typography>
                }
                {!targetAdmin &&
                    <Typography variant='h3'>
                        New Admin
                    </Typography>
                }
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={adminSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="25px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Username"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"username"}
                                        value={values.username}
                                        error={!!touched.username && !!errors.username}
                                        helperText={touched.username && errors.username}
                                        sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                    />
                                    {!targetAdmin &&
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"password"}
                                            value={values.password}
                                            error={!!touched.password && !!errors.password}
                                            helperText={touched.password && errors.password}
                                            sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                        />}

                                    <Typography gridColumn="span 2" variant='h4' mt="20px">Permissions</Typography>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label1">Users Page Permission</InputLabel>
                                        <Select
                                            labelId="filter-label1"
                                            label="Users Page Permission"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"users_perm"}
                                            value={values.users_perm}
                                            error={!!touched.users_perm && !!errors.users_perm}
                                        >
                                            <MenuItem value={0}>No</MenuItem>
                                            <MenuItem value={1}>Yes</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label5">Words Page Permission</InputLabel>
                                        <Select
                                            labelId="filter-label5"
                                            label="Words Page Permission"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"words_perm"}
                                            value={values.words_perm}
                                            error={!!touched.words_perm && !!errors.words_perm}
                                        >
                                            <MenuItem value={0}>No</MenuItem>
                                            <MenuItem value={1}>Yes</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label6">Questions Page Permission</InputLabel>
                                        <Select
                                            labelId="filter-label6"
                                            label="Questions Page Permission"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"questions_perm"}
                                            value={values.questions_perm}
                                            error={!!touched.questions_perm && !!errors.questions_perm}
                                        >
                                            <MenuItem value={0}>No</MenuItem>
                                            <MenuItem value={1}>Yes</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label7">Word Sets Page Permission</InputLabel>
                                        <Select
                                            labelId="filter-label7"
                                            label="Word Sets Page Permission"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"wordsets_perm"}
                                            value={values.wordsets_perm}
                                            error={!!touched.wordsets_perm && !!errors.wordsets_perm}
                                        >
                                            <MenuItem value={0}>No</MenuItem>
                                            <MenuItem value={1}>Yes</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label8">Announcements Page Permission</InputLabel>
                                        <Select
                                            labelId="filter-label8"
                                            label="Announcements Page Permission"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"announcements_perm"}
                                            value={values.announcements_perm}
                                            error={!!touched.announcements_perm && !!errors.announcements_perm}
                                        >
                                            <MenuItem value={0}>No</MenuItem>
                                            <MenuItem value={1}>Yes</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label9">Update Announcements Page Permission</InputLabel>
                                        <Select
                                            labelId="filter-label9"
                                            label="Update Announcements Page Permission"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"update_announcements_perm"}
                                            value={values.update_announcements_perm}
                                            error={!!touched.update_announcements_perm && !!errors.update_announcements_perm}
                                        >
                                            <MenuItem value={0}>No</MenuItem>
                                            <MenuItem value={1}>Yes</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label2">Notifications Permission</InputLabel>
                                        <Select
                                            labelId="filter-label2"
                                            label="Notifications Permission"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"notifications_perm"}
                                            value={values.notifications_perm}
                                            error={!!touched.notifications_perm && !!errors.notifications_perm}
                                        >
                                            <MenuItem value={0}>No</MenuItem>
                                            <MenuItem value={1}>Yes</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label3">Settings Permission</InputLabel>
                                        <Select
                                            labelId="filter-label3"
                                            label="Settings Permission"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"settings_perm"}
                                            value={values.settings_perm}
                                            error={!!touched.settings_perm && !!errors.settings_perm}
                                        >
                                            <MenuItem value={0}>No</MenuItem>
                                            <MenuItem value={1}>Yes</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label4">Admins Permission</InputLabel>
                                        <Select
                                            labelId="filter-label4"
                                            label="Admins Permission"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"admins_perm"}
                                            value={values.admins_perm}
                                            error={!!touched.admins_perm && !!errors.admins_perm}
                                        >
                                            <MenuItem value={0}>No</MenuItem>
                                            <MenuItem value={1}>Yes</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default AdminDialog