import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../global/Header';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import ReadingExamCategoryDialog from './ReadingExamCategoryDialog';
import ReadingExamCategoryBox from './ReadingExamCategoryBox';
import { AdminContext } from '../../../context/AdminContext';

function ReadingExamCategoriesPage() {
    const { token } = useContext(AdminContext);
    const [searchKey, setSearchKey] = useState("");
    const [readingExamCategories, setReadingExamCategories] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [readingExamCategoryToRemove, setReadingExamCategoryToRemove] = useState(null);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [targetCategory, setTargetCategory] = useState(null);
    const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);

    const fetchReadingExamCategories = () => {
        axios.get(`${process.env.REACT_APP_API_URL}reading/getReadingExamCategories?searchKey=${searchKey}&startIndex=${startIndex}&limit=50`)
            .catch((err) => {
                console.log("err: " + err);
                setReadingExamCategories([]);
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.categories && response.data.categories.length === 50);
                    if (startIndex === 0) {
                        console.log(response.data.categories);
                        setReadingExamCategories(response.data.categories);
                    } else {
                        setReadingExamCategories((prev) => [...prev, ...response.data.categories]);
                    }
                }
            });
    }

    useEffect(() => {
        fetchReadingExamCategories();
    }, [startIndex]);

    const handleRemoveDialogClose = async () => {
        setReadingExamCategoryToRemove(null);
        setRemoveDialogOpen(false);
    }

    const removeReadingExamCategory = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}reading/deleteReadingExamCategory?categoryId=${readingExamCategoryToRemove.id}&token=${token}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                if (startIndex === 0) {
                    fetchReadingExamCategories();
                } else {
                    setStartIndex(0);
                }
                handleRemoveDialogClose();
            }
        });
    }

    useEffect(() => {
        if (readingExamCategoryToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [readingExamCategoryToRemove])

    useEffect(() => {
        if (targetCategory) {
            setCategoryDialogOpen(true);
        }
    }, [targetCategory])

    return (
        <Box sx={{ p: "75px" }}>
            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{"Are you sure you want to delete the category?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Readings with this category will also be deleted!! You cannot undo this operation but you can add new category if you wish.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>Cancel</Button>
                    <Button onClick={() => removeReadingExamCategory()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <ReadingExamCategoryDialog targetCategory={targetCategory} dialogOpen={categoryDialogOpen} handleClose={(changed) => {
                setTargetCategory(null);
                setCategoryDialogOpen(false);
                if (changed) {
                    if (startIndex === 0) {
                        fetchReadingExamCategories();
                    } else {
                        setStartIndex(0);
                    }
                }

            }} />
            <Box display="flex" alignItems="center" gap="20px">

                <Header title="Reading Exam Categories" subtitle="You can see and manage reading exam categories from this page." />

                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="search-box">Search</InputLabel>
                    <OutlinedInput
                        id="search-box"
                        type='text'
                        onChange={(e) => {
                            setSearchKey(e.target.value ? e.target.value : "");
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="search button"
                                    onClick={() => {
                                        setReadingExamCategories([]);
                                        if (startIndex === 0) {
                                            fetchReadingExamCategories();
                                        } else {
                                            setStartIndex(0);
                                        }
                                    }}
                                    edge="end"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Search"
                    />
                </FormControl>
                <Button variant='contained' color='success' sx={{ m: 1, width: '25ch' }} onClick={() => {
                    setTargetCategory(null);
                    setCategoryDialogOpen(true);
                }} >
                    Add New Category
                </Button>
            </Box>
            <Box mt="30px">
                <InfiniteScroll
                    dataLength={readingExamCategories.length}
                    next={() => {
                        setStartIndex(readingExamCategories.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>You are viewing all categories.</b>
                        </p>
                    }
                >
                    <Box display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
                        {readingExamCategories.map(readingExamCategory => <ReadingExamCategoryBox key={readingExamCategory.id} readingExamCategory={readingExamCategory} editReadingExamCategory={(rc) => setTargetCategory(rc)} removeReadingExamCategory={(rc) => setReadingExamCategoryToRemove(rc)} />)}
                    </Box>
                </InfiniteScroll>
            </Box>
        </Box>
    )
}

export default ReadingExamCategoriesPage
