import { Box, IconButton, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { tokens } from '../theme';
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from 'react-router-dom';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import DatasetIcon from '@mui/icons-material/Dataset';
import CampaignIcon from '@mui/icons-material/Campaign';
import ArticleIcon from '@mui/icons-material/Article';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import 'react-pro-sidebar/dist/scss/styles.scss';
import { useContext } from 'react';
import { AdminContext } from '../context/AdminContext';

const Item = ({ title, to, icon, selected, setSelected, isCollapsed, setIsCollapsed }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem active={selected === title} style={{ color: colors.redAccent[100] }} onClick={() => setSelected(title)} icon={icon}>
            <Typography>
                {title}
            </Typography>
            <Link to={to} onClick={() => { if (isCollapsed === false) { setIsCollapsed(true); } }} />
        </MenuItem>
    )
}

function Sidebar() {
    const { admin } = useContext(AdminContext);
    const theme = useTheme();
    const colors = tokens();
    const [isCollapsed, setIsCollapsed] = useState("false");
    const [selected, setSelected] = useState("Panel");

    useEffect(() => {
        const selectedTitle = window.location.pathname.split("/")[1];
        switch (selectedTitle) {
            case "users":
                setSelected("Users");
                break;
            case "questions":
                setSelected("Questions");
                break;
            case "words":
                setSelected("Words");
                break;
            case "word-categories":
                setSelected("Words");
                break;
            case "wordsets":
                setSelected("Word Sets");
                break;
            case "readings":
                setSelected("Readings");
                break;
            case "reading-categories":
                setSelected("Readings");
                break;
            case "readings/new":
                setSelected("Readings");
                break;
            case "announcements":
                setSelected("Announcements");
                break;
            case "update-announcements":
                setSelected("Update Announcements");
                break;
            case "campaigns":
                setSelected("Campaigns");
                break;
            case "admins":
                setSelected("Admins");
                break;
            default:
                setSelected("Users");

        }
    }, [])

    return (
        <Box position="fixed" height="100vh" zIndex="10" sx={{
            "& .pro-sidebar-inner": {
                background: `${colors.primary[400]} !important`
            },
            "& .pro-icon-wrapper": {
                backgroundColor: "transparent !important"
            },
            "& .pro-inner-item:hover": {
                color: "#868dfb !important"
            },
            "& .pro-menu-item.active": {
                color: "#6870fa !important"
            }
        }}>
            <ProSidebar collapsed={isCollapsed}>
                <Menu style={{ padding: "0px" }}>
                    <MenuItem onClick={() => { setIsCollapsed(!isCollapsed) }}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            color: colors.grey[100]
                        }}>
                        {!isCollapsed && (
                            <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                                <Typography variant="h3" color={colors.grey[100]}>
                                    WORDISMO
                                </Typography>
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    <Box pl={isCollapsed ? undefined : "10%"}>
                        {admin.users_perm === 1 && <Item title="Users" to="/users" icon={<Groups2OutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.questions_perm === 1 && <Item title="Questions" to="/questions" icon={<QuizOutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.words_perm === 1 && <Item title="Words" to="/words" icon={<TranslateIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.wordsets_perm === 1 && <Item title="Word Sets" to="/wordsets" icon={<DatasetIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.words_perm === 1 && <Item title="Readings" to="/readings" icon={<ChromeReaderModeIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.announcements_perm === 1 && <Item title="Announcements" to="/announcements" icon={<CampaignIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.update_announcements_perm === 1 && <Item title="Update Announcements" to="/update-announcements" icon={<ArticleIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.settings_perm === 1 && <Item title="Campaigns" to="/campaigns" icon={<CardGiftcardIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.admins_perm === 1 && <Item title="Admins" to="/admins" icon={<AdminPanelSettingsIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />}
                    </Box>
                </Menu>
            </ProSidebar>

        </Box>
    )
}

export default Sidebar