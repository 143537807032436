import React, { useEffect, useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { tokens } from '../../../theme';

function UpdateAnnouncementDialog({ targetUpdateAnnouncement, dialogOpen, handleClose, handleFormSubmit }) {
    const colors = tokens();

    var initialValues = {
        title: targetUpdateAnnouncement?.title ? targetUpdateAnnouncement?.title : "",
        content: targetUpdateAnnouncement?.content ? targetUpdateAnnouncement?.content : "",
        position: targetUpdateAnnouncement?.position ? targetUpdateAnnouncement?.position : "",
        version: targetUpdateAnnouncement?.version ? targetUpdateAnnouncement.version : "",
        language: targetUpdateAnnouncement?.language ? targetUpdateAnnouncement.language : "",
        photo: null,
        photo_link: targetUpdateAnnouncement?.photo_link ? targetUpdateAnnouncement.photo_link : ""
    }

    var validationObject = {
        title: yup.string().required("required"),
        content: yup.string().required("required"),
        position: yup.number().min(0, "Can be at least 0").required("required"),
        version: yup.number().min(0, "Can be at least 0").required("required"),
        language: yup.string().required("required"),
        photo: yup.mixed().nullable().when('photo_link', {
            is: (photo_link) => !photo_link && photo_link === "",
            then: yup.mixed().required('Field is required')
        }),
        photo_link: yup.string()
    }

    const updateAnnouncementSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">
                {targetUpdateAnnouncement &&
                    <Typography variant='h3'>
                        Edit Update Announcement
                    </Typography>
                }
                {!targetUpdateAnnouncement &&
                    <Typography variant='h3'>
                        New Update Announcement
                    </Typography>
                }
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={updateAnnouncementSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box textAlign="center">
                                    <Typography variant="h6">Photo</Typography>
                                    <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                        {(values.photo || (values.photo_link && values.photo_link !== "")) && <img alt='update announcement' width="100px" src={values.photo ? URL.createObjectURL(values.photo) : values.photo_link} />}
                                        <input id="photo" name="photo" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("photo", event.target.files[0]); }} hidden />
                                    </Button>
                                </Box>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Title"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"title"}
                                        value={values.title}
                                        error={!!touched.title && !!errors.title}
                                        helperText={touched.title && errors.title}
                                        sx={{ gridColumn: "span 3", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Description"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={4}
                                        name={"content"}
                                        value={values.content}
                                        error={!!touched.content && !!errors.content}
                                        helperText={touched.content && errors.content}
                                        sx={{ gridColumn: "span 3", marginTop: "10px" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Position"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"position"}
                                        value={values.position}
                                        error={!!touched.position && !!errors.position}
                                        helperText={touched.position && errors.position}
                                        sx={{ gridColumn: "span 1", marginTop: "10px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Version"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"version"}
                                        value={values.version}
                                        error={!!touched.version && !!errors.version}
                                        helperText={touched.version && errors.version}
                                        sx={{ gridColumn: "span 1", marginTop: "10px" }}
                                    />
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1", marginTop: "10px" }}>
                                        <InputLabel id="filter-label">Language</InputLabel>
                                        <Select
                                            labelId="filter-label"
                                            label="Language"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"language"}
                                            value={values.language}
                                            error={!!touched.language && !!errors.language}
                                        >
                                            <MenuItem value={"turkish"}>Turkish</MenuItem>
                                            <MenuItem value={"french"}>French</MenuItem>
                                            <MenuItem value={"german"}>German</MenuItem>
                                            <MenuItem value={"spanish"}>Spanish</MenuItem>
                                            <MenuItem value={"portuguese"}>Portuguese</MenuItem>
                                            <MenuItem value={"italian"}>Italian</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default UpdateAnnouncementDialog