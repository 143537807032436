import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { tokens } from '../../../theme'
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const languagePrefixes = {
    turkish: "tr",
    french: "fr",
    german: "de",
    italian: "it",
    portuguese: "pt",
    spanish: "sp"
}

const categories = [
    {
        id: 8,
        name: "A1A2"
    },
    {
        id: 9,
        name: "Academic"
    },
    {
        id: 10,
        name: "B1B2"
    },
    {
        id: 11,
        name: "C1C2"
    },
    {
        id: 12,
        name: "Idioms"
    },
    {
        id: 13,
        name: "Phrasal Verbs"
    }
]

function QuestionBox({ question, updateQuestion, removeQuestion }) {
    const colors = tokens();
    const [selectedLanguage, setSelectedLanguage] = useState("turkish");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box width="700px">
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    updateQuestion({
                        id: question.id,
                        language: selectedLanguage,
                        category: question.category,
                        answer: question.answer,
                        info: question[languagePrefixes[selectedLanguage] + "_info"],
                        question: question[languagePrefixes[selectedLanguage] + "_question"],
                        a: question[languagePrefixes[selectedLanguage] + "_a"],
                        b: question[languagePrefixes[selectedLanguage] + "_b"],
                        c: question[languagePrefixes[selectedLanguage] + "_c"],
                        d: question[languagePrefixes[selectedLanguage] + "_d"],
                    });
                }}>
                    Edit
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    removeQuestion({
                        id: question.id,
                        language: selectedLanguage,
                        category: question.category,
                        answer: question.answer,
                        info: question[languagePrefixes[selectedLanguage] + "_info"],
                        question: question[languagePrefixes[selectedLanguage] + "_question"],
                        a: question[languagePrefixes[selectedLanguage] + "_a"],
                        b: question[languagePrefixes[selectedLanguage] + "_b"],
                        c: question[languagePrefixes[selectedLanguage] + "_c"],
                        d: question[languagePrefixes[selectedLanguage] + "_d"],
                    });
                }}>
                    Remove
                </MenuItem>
            </Menu>
            <Box position="relative" bgcolor={colors.grey[900]} p="20px" borderRadius="10px">
                <Box position="absolute" top={15} right={15}>
                    <IconButton
                        id="open-menu-button"
                        aria-controls={open ? 'menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <MoreVertIcon sx={{ scale: "1.2" }} />
                    </IconButton>
                </Box>
                <Box>
                    <Typography variant='h3' fontWeight="600">
                        {question[languagePrefixes[selectedLanguage] + "_question"] ? question[languagePrefixes[selectedLanguage] + "_question"] : "-"}
                    </Typography>
                    <Typography variant='h4' mt="10px">
                        {question[languagePrefixes[selectedLanguage] + "_info"] ? question[languagePrefixes[selectedLanguage] + "_info"] : "-"}
                    </Typography>
                    <Typography variant='h6' mt="10px">
                        Category: {question.category ? categories.find((ct) => question.category === ct.id)?.name : "-"}
                    </Typography>
                    <Box display="flex" mt="10px" gap="10px">
                        <Tooltip title="Turkish">
                            <Box
                                sx={{
                                    ...(selectedLanguage !== "turkish" ? {
                                        filter: "saturate(0%)",
                                        WebkitFilter: "saturate(0%)"
                                    } : {}),
                                    ":hover": {
                                        filter: "saturate(100%)",
                                        WebkitFilter: "saturate(100%)",
                                        cursor: "pointer"
                                    }
                                }}
                                onClick={() => setSelectedLanguage("turkish")}
                                component="img"
                                src='./flags/turkey.png'
                            />
                        </Tooltip>

                        <Tooltip title="French">
                            <Box
                                sx={{
                                    ...(selectedLanguage !== "french" ? {
                                        filter: "saturate(0%)",
                                        WebkitFilter: "saturate(0%)"
                                    } : {}),
                                    ":hover": {
                                        filter: "saturate(100%)",
                                        WebkitFilter: "saturate(100%)",
                                        cursor: "pointer"
                                    }
                                }}
                                onClick={() => setSelectedLanguage("french")}
                                component="img"
                                src='./flags/france.png'
                            />
                        </Tooltip>

                        <Tooltip title="German">
                            <Box
                                sx={{
                                    ...(selectedLanguage !== "german" ? {
                                        filter: "saturate(0%)",
                                        WebkitFilter: "saturate(0%)"
                                    } : {}),
                                    ":hover": {
                                        filter: "saturate(100%)",
                                        WebkitFilter: "saturate(100%)",
                                        cursor: "pointer"
                                    }
                                }}
                                onClick={() => setSelectedLanguage("german")}
                                component="img"
                                src='./flags/germany.png'
                            />
                        </Tooltip>

                        <Tooltip title="Italian">
                            <Box
                                sx={{
                                    ...(selectedLanguage !== "italian" ? {
                                        filter: "saturate(0%)",
                                        WebkitFilter: "saturate(0%)"
                                    } : {}),
                                    ":hover": {
                                        filter: "saturate(100%)",
                                        WebkitFilter: "saturate(100%)",
                                        cursor: "pointer"
                                    }
                                }}
                                onClick={() => setSelectedLanguage("italian")}
                                component="img"
                                src='./flags/italy.png'
                            />
                        </Tooltip>

                        <Tooltip title="Portuguese">
                            <Box
                                sx={{
                                    ...(selectedLanguage !== "portuguese" ? {
                                        filter: "saturate(0%)",
                                        WebkitFilter: "saturate(0%)"
                                    } : {}),
                                    ":hover": {
                                        filter: "saturate(100%)",
                                        WebkitFilter: "saturate(100%)",
                                        cursor: "pointer"
                                    }
                                }}
                                onClick={() => setSelectedLanguage("portuguese")}
                                component="img"
                                src='./flags/portugal.png'
                            />
                        </Tooltip>

                        <Tooltip title="Spanish">
                            <Box
                                sx={{
                                    ...(selectedLanguage !== "spanish" ? {
                                        filter: "saturate(0%)",
                                        WebkitFilter: "saturate(0%)"
                                    } : {}),
                                    ":hover": {
                                        filter: "saturate(100%)",
                                        WebkitFilter: "saturate(100%)",
                                        cursor: "pointer"
                                    }
                                }}
                                onClick={() => setSelectedLanguage("spanish")}
                                component="img"
                                src='./flags/spain.png'
                            />
                        </Tooltip>

                    </Box>
                </Box>
                <Box component="hr" my="20px" border={"1px solid " + colors.grey[500]} />
                <Box display="flex" justifyContent="space-evenly" alignItems="center">
                    <Box border="2px solid" borderRadius="10px" p="10px" borderColor={question.answer === "a" ? colors.greenAccent[400] : "black"} >
                        <Typography variant='h5'>
                            {question[languagePrefixes[selectedLanguage] + "_a"] ? question[languagePrefixes[selectedLanguage] + "_a"] : "-"}
                        </Typography>
                    </Box>
                    <Box border="2px solid" borderRadius="10px" p="10px" borderColor={question.answer === "b" ? colors.greenAccent[400] : "black"} >
                        <Typography variant='h5'>
                            {question[languagePrefixes[selectedLanguage] + "_b"] ? question[languagePrefixes[selectedLanguage] + "_b"] : "-"}
                        </Typography>
                    </Box>
                    <Box border="2px solid" borderRadius="10px" p="10px" borderColor={question.answer === "c" ? colors.greenAccent[400] : "black"} >
                        <Typography variant='h5'>
                            {question[languagePrefixes[selectedLanguage] + "_c"] ? question[languagePrefixes[selectedLanguage] + "_c"] : "-"}
                        </Typography>
                    </Box>
                    <Box border="2px solid" borderRadius="10px" p="10px" borderColor={question.answer === "d" ? colors.greenAccent[400] : "black"} >
                        <Typography variant='h5'>
                            {question[languagePrefixes[selectedLanguage] + "_d"] ? question[languagePrefixes[selectedLanguage] + "_d"] : "-"}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default QuestionBox