import { Box, FormControl, IconButton, InputLabel, Menu, MenuItem, OutlinedInput } from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import PersonIcon from '@mui/icons-material/Person';
import VerifiedIcon from '@mui/icons-material/Verified';
import SearchIcon from '@mui/icons-material/Search';
import GetAppIcon from '@mui/icons-material/GetApp';
import { tokens } from '../../theme';
import Header from '../../global/Header';
import UserDialog from './UserDialog';
import * as XLSX from 'xlsx';
import ExportDialog from './ExportDialog';
import CardGiftcard from '@mui/icons-material/CardGiftcard';

function UsersPage() {
    const colors = tokens();
    const [isLoading, setLoading] = useState(true);
    const [searchKey, setSearchKey] = useState("");
    const [searchId, setSearchId] = useState(null);
    const [orderModel, setOrderModel] = useState(null);
    const [rowCount, setRowCount] = useState(0);
    const [users, setUsers] = useState([]);
    const [targetUser, setTargetUser] = useState(null);
    const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
    const [exportDialogOpen, setExportDialogOpen] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });

    const makeUserPremium = (userId, duration) => {
        axios.post(`https://api.revenuecat.com/v1/subscribers/${userId}/entitlements/premium_pack/promotional`, {
            duration: duration
        }, {
            headers: {
                "Authorization": `Bearer sk_jUgQLvWJQdPDqDcyluZOQlMTUEJhB`,
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.status === 201) {
                    var userIx = users.findIndex(u => u.id === userId);
                    if (userIx !== null && userIx !== undefined && userIx !== -1) {
                        setUsers(prev => { var newUsers = [...prev]; newUsers[userIx].is_premium = 1; return newUsers; })
                    }
                }
            });
    }

    const revokeUserPremium = (userId) => {
        axios.post(`https://api.revenuecat.com/v1/subscribers/${userId}/entitlements/premium_pack/revoke_promotionals`, {}, {
            headers: {
                "Authorization": `Bearer sk_jUgQLvWJQdPDqDcyluZOQlMTUEJhB`,
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.status === 200) {
                    var userIx = users.findIndex(u => u.id === userId);
                    if (userIx !== null && userIx !== undefined && userIx !== -1) {
                        setUsers(prev => { var newUsers = [...prev]; newUsers[userIx].is_premium = 0; return newUsers; })
                    }
                }
            });
    }

    const updateUser = async (userId, user) => {
        var userIx = users.findIndex(u => u.id === userId);
        var newDiamond = user.diamond;
        if (userIx !== -1 && newDiamond && newDiamond !== users[userIx].diamond) {
            var diamondToAdd = newDiamond - users[userIx].diamond;
            await axios.post(`${process.env.REACT_APP_API_URL}user/addDiamond`, {
                id: userId,
                addCount: diamondToAdd
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {

                    }
                });
        }
        if (user.photo) {
            await axios.post(`${process.env.REACT_APP_API_URL}user/uploadProfilePhoto`, {
                profilePhoto: user.photo
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        delete user.photo;
                        delete user.profile_photo_link;
                        user.profile_photo_path = response.data.photoPath;
                    }
                });
        }



        var userToUpdate = { ...user };
        delete userToUpdate.photo;
        delete userToUpdate.profile_photo_link;
        delete userToUpdate.diamond;

        await axios.patch(`${process.env.REACT_APP_API_URL}user/updateUser?userId=${userId}`, {
            user: userToUpdate
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.isUpdated === true && response.data.status === 201) {
                    if (userIx !== null && userIx !== undefined && userIx !== -1) {
                        setUsers(prev => {
                            var newUsers = [...prev];
                            newUsers[userIx] = { ...newUsers[userIx], ...userToUpdate };
                            if (newDiamond) {
                                newUsers[userIx].diamond = newDiamond;
                            }
                            return newUsers;
                        })
                    }
                    if (editUserDialogOpen) {
                        setEditUserDialogOpen(false);
                    }
                }
            });
    }

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1.2 },
        { field: 'profile_photo_link', headerName: "PHOTO", sortable: false, renderCell: (params) => <img src={params.value} alt='profile-pic' style={{ height: "30px", width: "30px" }} />, width: 70 },
        {
            field: 'name',
            headerName: 'NAME',
            flex: 1
        }, {
            field: 'mail',
            headerName: 'MAIL',
            flex: 1
        }, {
            field: 'phone_number',
            headerName: 'PHONE',
            flex: 1
        }, {
            field: 'bio',
            headerName: 'BIOGRAPHY',
            flex: 1
        }, {
            field: 'heart_count',
            headerName: 'HEARTS'
        }, {
            field: 'gold',
            headerName: 'GOLD'
        }, {
            field: 'diamond',
            headerName: 'DIAMOND'
        }, {
            field: 'contact_policy',
            headerName: 'CONTACT POLICY',
            type: "boolean"
        }, {
            field: 'is_premium',
            headerName: 'PREMIUM',
            type: "boolean"
        }, {
            field: 'is_verified',
            headerName: 'VERIFIED',
            type: "boolean"
        }, {
            field: "sign_up_date", headerName: "SIGN UP",
            renderCell: ({ row: { sign_up_date } }) => {
                return new Date(sign_up_date).toLocaleDateString()
            }
        }, {
            field: "last_online_date", headerName: "LAST ONLINE",
            renderCell: ({ row: { last_online_date } }) => {
                return new Date(last_online_date).toLocaleDateString()
            }
        },
        {
            field: 'actions',
            type: 'actions',
            sortable: false,
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<PersonIcon />}
                    label="Edit User"
                    onClick={() => setTargetUser(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<CardGiftcard />}
                    label="Used Coupons"
                    onClick={() => {
                        window.location.href = `/campaigns/${params.row.id}`;
                    }}
                    showInMenu
                />,
                ...(params.row.is_premium === 0 ?
                    [<GridActionsCellItem
                        icon={<WorkspacePremiumIcon />}
                        label="Make user premium(1 Year)"
                        onClick={() => makeUserPremium(params.row.id, "yearly")}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<WorkspacePremiumIcon />}
                        label="Make user premium(1 Year)"
                        onClick={() => makeUserPremium(params.row.id, "six_month")}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<WorkspacePremiumIcon />}
                        label="Make user premium(1 Month)"
                        onClick={() => makeUserPremium(params.row.id, "monthly")}
                        showInMenu
                    />] :
                    [
                        <GridActionsCellItem
                            icon={<WorkspacePremiumIcon />}
                            label="Revoke user's premium"
                            onClick={() => revokeUserPremium(params.row.id)}
                            showInMenu
                        />
                    ])
                ,
                <GridActionsCellItem
                    icon={<VerifiedIcon />}
                    label="Toggle Verified State"
                    onClick={() => updateUser(params.row.id, { is_verified: (params.row.is_verified === 1 ? 0 : 1) })}
                    showInMenu
                />
            ],
        }
    ];

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}user/getUsers?startIndex=${paginationModel.pageSize * paginationModel.page}&limit=${paginationModel.pageSize}${searchKey ? `&searchKey=${searchKey}` : ""}${searchId ? `&searchId=${searchId}` : ""}${orderModel ? `&orderField=${orderModel.field}&orderType=${orderModel.sort}` : ""}`)
            .catch((err) => {
                setUsers([]);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setUsers(response.data.users);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setLoading(false);
            });
    }, [paginationModel]);

    useEffect(() => {
        if (targetUser) {
            setEditUserDialogOpen(true);
        }
    }, [targetUser]);

    const handleEditUserDialogClose = () => {
        setTargetUser(null);
        setEditUserDialogOpen(false);
    }



    const exportUsers = ({ fileExtention, contactPolicy, language, proStatus, minGold, minDiamond }) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}user/getAllUsersToExport?` +
            `${contactPolicy ? "contactPolicy=" + contactPolicy + "&" : ""}` +
            `${language !== "all" ? "language=" + language + "&" : ""}` +
            `${proStatus !== "any" ? "proStatus=" + proStatus + "&" : ""}` +
            `${minGold ? "minGold=" + minGold + "&" : ""}` +
            `${minDiamond ? "minDiamond=" + minDiamond + "&" : ""}` +
            "fetch=fetch"
        )
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    const worksheetApprovers = XLSX.utils.json_to_sheet(response.data.users);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheetApprovers, "Users");
                    XLSX.writeFile(workbook, `Wordismo-Users-${(new Date()).toISOString()}.${fileExtention}`);
                }
            });
        setExportDialogOpen(false);
    }

    return (
        <Box sx={{ p: "75px" }}>
            <UserDialog dialogOpen={editUserDialogOpen} handleClose={handleEditUserDialogClose} targetUser={targetUser} handleFormSubmit={(values) => updateUser(targetUser.id, values)} />
            <ExportDialog dialogOpen={exportDialogOpen} handleClose={() => setExportDialogOpen(false)} handleFormSubmit={(values) => exportUsers(values)} />

            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="20px">
                    <Header title="Users" subtitle="You can see and manage users from this page." />
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box">Search</InputLabel>
                        <OutlinedInput
                            id="search-box"
                            type='text'
                            onChange={(e) => {
                                setSearchKey(e.target.value ? e.target.value : "");
                            }}
                            label="Search"
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box">Search(Id)</InputLabel>
                        <OutlinedInput
                            id="search-box"
                            type='number'
                            onChange={(e) => {
                                setSearchId(e.target.value ? e.target.value : "");
                            }}
                            label="Search(Id)"
                        />
                    </FormControl>

                    <IconButton
                        aria-label="search button"
                        sx={{ background: colors.grey[900], borderRadius: "10px", padding: "12px" }}
                        onClick={() => { setPaginationModel(prev => { return { ...prev, page: 0 } }) }}
                        edge="end"
                    >
                        <SearchIcon />
                    </IconButton>
                </Box>
                <IconButton
                    sx={{ background: colors.grey[900], borderRadius: "10px", padding: "12px", mr: "5px" }}
                    onClick={() => { setExportDialogOpen(true) }}
                    edge="end"
                >
                    <GetAppIcon />
                </IconButton>
            </Box>
            <DataGrid
                rows={users}
                columns={columns}
                rowCount={rowCount}
                loading={isLoading}
                pageSizeOptions={[10, 25, 50, 75, 100]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode='server'
                onSortModelChange={(model) => {
                    setOrderModel((prev) => model && model.length > 0 ? model[0] : null);
                    setPaginationModel(prev => { return { ...prev, page: 0 } });
                }}
                disableColumnFilter
                sx={{
                    ".MuiDataGrid-columnHeaders": {
                        background: colors.grey[900]
                    }
                }}
            />
        </Box>
    )
}

export default UsersPage