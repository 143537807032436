import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useState } from 'react'

function SentenceWordBox({ word, openEditDialog, openRemoveDialog }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>

                <MenuItem onClick={() => {
                    openEditDialog();
                    handleClose();
                }}>
                    Edit
                </MenuItem>
                <MenuItem onClick={() => {
                    openRemoveDialog();
                    handleClose();
                }}>
                    Remove
                </MenuItem>
            </Menu>
            <Box width={"100%"} p={"10px"} border={"1px solid #9f9f9f"} borderRadius={"10px"} sx={{ gridColumn: "span 1" }}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant='h3'>{word.word}</Typography>
                    <IconButton
                        id="open-menu-button"
                        aria-controls={openMenu ? 'menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? 'true' : undefined}
                        onClick={handleClick}>
                        <MoreVertIcon sx={{ scale: "1.2" }} />
                    </IconButton>

                </Box>
                <hr />
                <Typography variant='h5'><b>Type:</b> {word.type}</Typography>
                <Typography variant='h5'><b>Highlight:</b> {word.highlight}</Typography>
                <Typography variant='h5'><b>Definition:</b> {word.definition}</Typography>
            </Box>
        </>

    )
}

export default SentenceWordBox