import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../global/Header';
// import ReadingBox from './ReadingBox';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import ReadingBox from './ReadingBox';
import { AdminContext } from '../../context/AdminContext';

function ReadingsPage() {
    const { token } = useContext(AdminContext);
    const [searchKey, setSearchKey] = useState("");
    const [searchCategoryType, setSearchCategoryType] = useState("none");
    const [searchCategoryId, setSearchCategoryId] = useState(-1);
    const [searchCategory, setSearchCategory] = useState("none");
    const [readings, setReadings] = useState([]);
    const [subjectCategories, setSubjectCategories] = useState([]);
    const [examCategories, setExamCategories] = useState([]);
    const [levelCategories, setLevelCategories] = useState([
        "A1",
        "A2",
        "B1",
        "B2",
        "C1",
        "C2"
    ]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [readingToRemove, setReadingToRemove] = useState(null);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

    const fetchReadings = () => {
        axios.get(`${process.env.REACT_APP_API_URL}reading/getReadings?searchKey=${searchKey}&startIndex=${startIndex}&limit=50${(searchCategoryType !== "none" && ((searchCategoryType === "level_category" && searchCategory !== "none") || (searchCategoryType !== "level_category" && searchCategoryId !== -1))) ? `&categoryType=${searchCategoryType}${searchCategoryType === "level_category" ? `&category=${searchCategory}` : `&categoryId=${searchCategoryId}`}` : ""}`)
            .catch((err) => {
                console.log("err: " + err);
                setReadings([]);
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.readings && response.data.readings.length === 50);
                    if (startIndex === 0) {
                        setReadings(response.data.readings);
                    } else {
                        setReadings((prev) => [...prev, ...response.data.readings]);
                    }
                }
            });
    }

    const fetchCategories = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}reading/getReadingCategories?token=${token}`).catch((err) => {
            console.log("err: " + err);
            setSubjectCategories([])
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                setSubjectCategories(response.data.categories);
            }
        });
    }

    const fetchExamCategories = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}reading/getReadingExamCategories?token=${token}`).catch((err) => {
            console.log("err: " + err);
            setExamCategories([])
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                setExamCategories(response.data.categories);
            }
        });
    }

    useEffect(() => {
        fetchReadings();
    }, [startIndex]);

    useEffect(() => {
        if (startIndex === 0) {
            fetchReadings();
        } else {
            setStartIndex(0);
        }
    }, [searchCategoryId, searchCategory]);

    useEffect(() => {
        fetchCategories();
        fetchExamCategories();
    }, []);

    const handleRemoveDialogClose = async () => {
        setReadingToRemove(null);
        setRemoveDialogOpen(false);
    }

    const removeReading = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}reading/deleteReading?readingId=${readingToRemove.id}&token=${token}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                if (startIndex === 0) {
                    fetchReadings();
                } else {
                    setStartIndex(0);
                }
                handleRemoveDialogClose();
            }
        });
    }

    useEffect(() => {
        if (readingToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [readingToRemove])

    return (
        <Box sx={{ p: "75px" }}>
            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{"Are you sure you want to delete the reading?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You cannot undo this operation but you can add new reading if you wish.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>Cancel</Button>
                    <Button onClick={() => removeReading()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <Box display="flex" alignItems="center" gap="20px">

                <Header title="Readings" subtitle="You can see and manage readings from this page." />

                <Button variant='contained' color='success' sx={{ m: 1, width: '25ch' }} onClick={() => {
                    window.open(`${process.env.REACT_APP_WEB_URL}readings/new`, '_self');
                }} >
                    Add New Reading
                </Button>
                <Button variant='contained' color='warning' sx={{ m: 1 }} onClick={() => {
                    window.open(`${process.env.REACT_APP_WEB_URL}reading-categories`, '_self');
                }} >
                    Reading Categories
                </Button>
                <Button variant='contained' color='warning' sx={{ m: 1 }} onClick={() => {
                    window.open(`${process.env.REACT_APP_WEB_URL}reading-exam-categories`, '_self');
                }} >
                    Reading Exam Categories
                </Button>
            </Box>
            <Box mt="30px">
                <Box display="flex" alignItems="center" gap="20px" >
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box">Search</InputLabel>
                        <OutlinedInput
                            id="search-box"
                            type='text'
                            onChange={(e) => {
                                setSearchKey(e.target.value ? e.target.value : "");
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="search button"
                                        onClick={() => {
                                            setReadings([]);
                                            if (startIndex === 0) {
                                                fetchReadings();
                                            } else {
                                                setStartIndex(0);
                                            }
                                        }}
                                        edge="end"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Search"
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="categoryType">Category Type</InputLabel>
                        <Select
                            id="categoryType"
                            value={searchCategoryType}
                            label="Subject Category"
                            onChange={(e) => {
                                setSearchCategory("none");
                                setSearchCategoryId(-1);
                                setSearchCategoryType(e.target.value);
                            }}
                        >
                            <MenuItem value={"none"}>None</MenuItem>
                            <MenuItem value={"subject_category"}>Subject Category</MenuItem>
                            <MenuItem value={"level_category"}>Level Category</MenuItem>
                            <MenuItem value={"exam_category"}>Exam Category</MenuItem>
                        </Select>
                    </FormControl>
                    {searchCategoryType === "subject_category" &&
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="searchSubjectCategoryId">Subject Category</InputLabel>
                            <Select
                                id="searchSubjectCategoryId"
                                value={searchCategoryId}
                                label="Subject Category"
                                onChange={(e) => {
                                    setSearchCategoryId(e.target.value);
                                }}
                            >
                                <MenuItem value={-1}>All</MenuItem>
                                {subjectCategories.map((category) => <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>)}
                            </Select>
                        </FormControl>
                    }
                    {searchCategoryType === "exam_category" &&
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="searchExamCategoryId">Exam Category</InputLabel>
                            <Select
                                id="searchExamCategoryId"
                                value={searchCategoryId}
                                label="Exam Category"
                                onChange={(e) => {
                                    setSearchCategoryId(e.target.value);
                                }}
                            >
                                <MenuItem value={-1}>All</MenuItem>
                                {examCategories.map((category) => <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>)}
                            </Select>
                        </FormControl>
                    }
                    {searchCategoryType === "level_category" &&
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="searchLevelCategory">Level Category</InputLabel>
                            <Select
                                id="searchLevelCategory"
                                value={searchCategory}
                                label="Level Category"
                                onChange={(e) => {
                                    setSearchCategory(e.target.value);
                                }}
                            >
                                <MenuItem value={"none"}>All</MenuItem>
                                {levelCategories.map((category) => <MenuItem key={category} value={category}>{category.toUpperCase()}</MenuItem>)}
                            </Select>
                        </FormControl>
                    }
                </Box>
                <InfiniteScroll
                    dataLength={readings.length}
                    next={() => {
                        setStartIndex(readings.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>You are viewing all readings.</b>
                        </p>
                    }
                >
                    <Box>
                        {readings.map(reading => <ReadingBox key={reading.id} reading={reading} removeReading={(r) => setReadingToRemove(r)} />)}
                    </Box>
                </InfiniteScroll>
            </Box>
        </Box>
    )
}

export default ReadingsPage
