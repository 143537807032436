import React, { useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { tokens } from '../../theme';
import axios from 'axios';

function NotificationDialog({ dialogOpen, handleClose }) {
    const colors = tokens();

    var initialValues = {
        message: "",
        link: "",
        language: "turkish"
    }

    var validationObject = {
        message: yup.string().required("required"),
        link: yup.string(),
        language: yup.string().required("required"),
    }

    const notificationSchema = yup.object().shape(validationObject);

    const handleFormSubmit = async (values) => {
        await axios.post(`${process.env.REACT_APP_API_URL}notif/createNotif`, {
            notif: {
                type: "system",
                ...values
            }
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                handleClose();
            }
        });

    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" mx="150px" textAlign="center">
                <Typography variant='h3'>
                    Notification
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={notificationSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mb="25px">

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Message"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={3}
                                        name={"message"}
                                        value={values.message}
                                        error={!!touched.message && !!errors.message}
                                        helperText={touched.message && errors.message}
                                        sx={{ gridColumn: "span 4", marginTop: "10px" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Link"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"link"}
                                        value={values.link}
                                        error={!!touched.link && !!errors.link}
                                        helperText={touched.link && errors.link}
                                        sx={{ gridColumn: "span 4", marginTop: "10px" }}
                                    />

                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2", marginTop: "10px" }}>
                                        <InputLabel id="filter-label">Language</InputLabel>
                                        <Select
                                            labelId="filter-label"
                                            label="Language"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"language"}
                                            value={values.language}
                                            error={!!touched.language && !!errors.language}
                                        >
                                            <MenuItem value={"turkish"}>Turkish</MenuItem>
                                            <MenuItem value={"french"}>French</MenuItem>
                                            <MenuItem value={"german"}>German</MenuItem>
                                            <MenuItem value={"spanish"}>Spanish</MenuItem>
                                            <MenuItem value={"portuguese"}>Portuguese</MenuItem>
                                            <MenuItem value={"italian"}>Italian</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default NotificationDialog