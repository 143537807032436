import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { tokens } from '../../../theme'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonIcon from '@mui/icons-material/Person';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import TranslateIcon from '@mui/icons-material/Translate';

function WordSetBox({ wordSet, removeWordSet }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const colors = tokens();
    return (
        <Box width="400px">
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    window.open(`${process.env.REACT_APP_WEB_URL}wordsets/${wordSet.id}`, '_self');
                }}>
                    Edit
                </MenuItem>
                <MenuItem onClick={() => {
                    removeWordSet(wordSet);
                    handleClose()
                }}>
                    Remove
                </MenuItem>
            </Menu>

            <Box position="relative" bgcolor={colors.grey[900]} p="20px" borderRadius="10px">
                <Box position="absolute" top={15} right={15}>

                    <IconButton
                        id="open-menu-button"
                        aria-controls={open ? 'menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <MoreVertIcon sx={{ scale: "1.2" }} />
                    </IconButton>

                </Box>
                <Box>
                    <Box display="flex" alignItems="center">

                        <Typography variant='h3' fontWeight="600">
                            {wordSet.name}
                        </Typography>
                        <Tooltip title={wordSet.language[0].toUpperCase() + wordSet.language.slice(1)} ml="10px">
                            <Box
                                component="img"
                                src={wordSet.language === "turkish" ? './flags/turkey.png' : (wordSet.language === "german" ? './flags/germany.png' : (wordSet.language === "italian" ? './flags/italy.png' : (wordSet.language === "spanish" ? './flags/spain.png' : './flags/portugal.png')))}
                            />
                        </Tooltip>
                    </Box>
                    <Typography variant='h6' >
                        {wordSet.privacy === 2 ? "Private" : (wordSet.privacy === 1 ? "Followers Only" : "Public")}
                    </Typography>

                    <Box display="flex" alignItems="center" gap="10px" mt="10px">
                        <img src={wordSet.profile_photo_link} alt='profile-pic' style={{ height: "30px", width: "30px" }} />
                        <Typography variant='h4'>
                            {wordSet.username}
                        </Typography>
                    </Box>
                </Box>
                <Box component="hr" my="20px" border={"1px solid " + colors.grey[500]} />
                <Box display="flex" justifyContent="space-evenly" alignItems="center">
                    <Box display="flex" alignItems="center" gap="5px">
                        <FavoriteIcon />
                        <Typography variant='h4'>
                            {wordSet.like_count}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap="5px">
                        <PersonIcon />
                        <Typography variant='h4'>
                            {wordSet.follower_count}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap="5px">
                        <AutoStoriesIcon />
                        <Typography variant='h4'>
                            {wordSet.study_count}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap="5px">
                        <TranslateIcon />
                        <Typography variant='h4'>
                            {wordSet.word_count}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default WordSetBox