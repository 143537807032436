import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import axios from 'axios';

function SentenceWordDialog({ targetWord, choosenSentenceIndex, parentFormValues, parentFormSetFieldValue, dialogOpen, handleClose }) {

    var initialValues = {
        word: targetWord?.word ? targetWord?.word : "",
        highlight: targetWord?.highlight ? targetWord.highlight : "",
        type: targetWord?.type ? targetWord.type : "",
        definition: targetWord?.definition ? targetWord.definition : "",
        translations: targetWord?.translations ? targetWord.translations : [{
            translation: "",
            language: "turkish"
        },
        {
            translation: "",
            language: "german"
        },
        {
            translation: "",
            language: "spanish"
        },
        {
            translation: "",
            language: "french"
        },
        {
            translation: "",
            language: "portuguese"
        },
        {
            translation: "",
            language: "italian"
        }],
    }

    var validationObject = {
        word: yup.string().required("required"),
        highlight: yup.string().required("required"),
        type: yup.string().required("required"),
        definition: yup.string().required("required"),
        translations: yup.array().of(
            yup.object({
                translation: yup.string().required("required"),
                language: yup.string().required("required")
            })
        )
    }

    const wordSchema = yup.object().shape(validationObject);

    const handleFormSubmit = (values) => {
        if (!targetWord) {
            parentFormSetFieldValue(`sentences[${choosenSentenceIndex}].words[${parentFormValues.sentences[choosenSentenceIndex].words.length}]`, values);
        } else {
            let wordIx = parentFormValues.sentences[choosenSentenceIndex].words.findIndex((w) => w.word === targetWord.word && w.definition === targetWord.definition);
            parentFormSetFieldValue(`sentences[${choosenSentenceIndex}].words[${wordIx}]`, values);
        }
        handleClose()
    }

    const performTranslate = (word, languageSymbol, cb) => {
        axios.patch(`${process.env.REACT_APP_API_URL}translateWithBing`, {
            sentence: word,
            languageSymbol: languageSymbol
        }).catch((err) => {
            console.log("err", err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                cb(response.data.translation);
            } else {
                cb("-");
            }
        })

    }

    const translateWord = (word, translations, setFieldValue) => {
        performTranslate(word, "tr", (translation) => {
            var ix = translations.findIndex((t) => t.language === "turkish");
            setFieldValue(`translations[${ix}].translation`, translation)
        });
        performTranslate(word, "de", (translation) => {
            var ix = translations.findIndex((t) => t.language === "german");
            setFieldValue(`translations[${ix}].translation`, translation)
        });
        performTranslate(word, "es", (translation) => {
            var ix = translations.findIndex((t) => t.language === "spanish");
            setFieldValue(`translations[${ix}].translation`, translation)
        });
        performTranslate(word, "fr", (translation) => {
            var ix = translations.findIndex((t) => t.language === "french");
            setFieldValue(`translations[${ix}].translation`, translation)
        });
        performTranslate(word, "pt-PT", (translation) => {
            var ix = translations.findIndex((t) => t.language === "portuguese");
            setFieldValue(`translations[${ix}].translation`, translation)
        });
        performTranslate(word, "it", (translation) => {
            var ix = translations.findIndex((t) => t.language === "italian");
            setFieldValue(`translations[${ix}].translation`, translation)
        });
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box mx="20px" mt="20px" textAlign="center">
                {targetWord &&
                    <Typography variant='h3'>
                        Edit Word ({targetWord?.word})
                    </Typography>
                }
                {!targetWord &&
                    <Typography variant='h3'>
                        New Word
                    </Typography>
                }
                <Typography variant='h5' mt="10px" fontStyle="italic" >
                    <u>{parentFormValues?.sentences[choosenSentenceIndex]?.sentence}</u>
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={wordSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(6, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Word"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"word"}
                                        value={values.word}
                                        error={!!touched.word && !!errors.word}
                                        helperText={touched.word && errors.word}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Highlight"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"highlight"}
                                        value={values.highlight}
                                        error={!!touched.highlight && !!errors.highlight}
                                        helperText={touched.highlight && errors.highlight}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Type (verb, adjective etc.)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"type"}
                                        value={values.type}
                                        error={!!touched.type && !!errors.type}
                                        helperText={touched.type && errors.type}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Definition"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"definition"}
                                        value={values.definition}
                                        error={!!touched.definition && !!errors.definition}
                                        helperText={touched.definition && errors.definition}
                                        sx={{ gridColumn: "span 6" }}
                                    />

                                    <Button color="success" variant="contained" sx={{ gridColumn: "3 / span 2", marginTop: "20px" }} onClick={() => translateWord(values.word, values.translations, setFieldValue)}>
                                        <Typography>Translate</Typography>
                                    </Button>

                                    {values.translations.map((translation, j) =>
                                        <TextField
                                            key={`translation-${j}`}
                                            variant="filled"
                                            type="text"
                                            label={`Word(${translation.language})`}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"translations[" + j + "].translation"}
                                            value={values.translations[j].translation}
                                            // error={!!touched?.sentences[i]?.translations && !!touched?.sentences[i]?.translations[j] && !!touched?.sentences[i]?.translations[j]?.translation && !!errors?.sentences[i]?.translations && !!errors?.sentences[i]?.translations[j] && !!errors?.sentences[i]?.translations[j]?.translation}
                                            // helperText={touched?.sentences[i]?.translations && touched?.sentences[i]?.translations[j] && touched?.sentences[i]?.translations[j]?.translation && errors?.sentences[i]?.translations && errors?.sentences[i]?.translations[j] && errors?.sentences[i]?.translations[j]?.translation}
                                            sx={{ gridColumn: "span 3" }}
                                        />
                                    )}
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default SentenceWordDialog