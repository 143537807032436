import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { tokens } from '../../../../theme';

function UserCouponBox({ coupon }) {
    const colors = tokens();
    return (
        <Box mt={"30px"} sx={{ gridColumn: "span 1" }} >

            <Box position="relative" bgcolor={colors.grey[900]} borderRadius="10px">
                <Box>
                    <Box mt={"5px"} mr={"35px"} p="15px" overflow={"hidden"}>
                        <Tooltip title={coupon.campaign_title}>
                            <Typography variant='h3' fontWeight="600">
                                {coupon.campaign_title}
                            </Typography>
                        </Tooltip>

                        <hr />

                        <Box display={"flex"} justifyContent={"space-between"} px={"5px"}>
                            <Box>
                                <Typography variant='h6' >
                                    Date of use: {(new Date(coupon.created_date)).toLocaleDateString()} {(new Date(coupon.created_date)).toLocaleTimeString()}
                                </Typography>
                                <Typography variant='h6' >
                                    Code: {coupon.code}
                                </Typography>
                                <Typography variant='h6' >
                                    {coupon.premium && coupon.premium !== "none" ? <b>Premium: {coupon.premium}</b> : "Premium: -"}
                                </Typography>
                            </Box>

                            <Box>
                                <Typography variant='h6' >
                                    {coupon.heart ? <b>Heart: {coupon.heart}</b> : "Heart: -"}
                                </Typography>
                                <Typography variant='h6' >
                                    {coupon.diamond ? <b>Diamond: {coupon.diamond}</b> : "Diamond: -"}
                                </Typography>
                                <Typography variant='h6' >
                                    {coupon.gold ? <b>Gold: {coupon.gold}</b> : "Gold: -"}
                                </Typography>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default UserCouponBox