import React, { useEffect, useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';

function WordDialog({ targetWord, dialogOpen, handleClose, handleFormSubmit }) {

    const [subCategories, setSubCategories] = useState([]);

    var initialValues = {
        category: targetWord?.category ? targetWord?.category : "",
        subcategory: targetWord?.subcategory ? targetWord?.subcategory : "",
        english_word: targetWord?.english_word ? targetWord?.english_word : "",
        translation: targetWord?.translation ? targetWord.translation : "",
        definition: targetWord?.definition ? targetWord.definition : "",
        example: targetWord?.example ? targetWord.example : "",
        example_highlight: targetWord?.example_highlight ? targetWord.example_highlight : "",
        example_translation: targetWord?.example_translation ? targetWord.example_translation : "",
        example_translation_highlight: targetWord?.example_translation_highlight ? targetWord.example_translation_highlight : ""
    }

    var validationObject = {
        category: yup.number().min(0, "Please Select").required("required"),
        subcategory: yup.number().min(0, "Please Select").required("required"),
        english_word: yup.string().required("required"),
        translation: yup.string().required("required"),
        definition: yup.string().required("required"),
        example: yup.string().required("required"),
        example_highlight: yup.string().required("required"),
        example_translation: yup.string().required("required"),
        example_translation_highlight: yup.string().required("required")
    }

    const wordSchema = yup.object().shape(validationObject);

    useEffect(() => {
        if (initialValues.category) {
            fetchSubcategories(initialValues.category);
        }
    }, [initialValues.category])


    const fetchSubcategories = (categoryId) => {
        axios.get(`${process.env.REACT_APP_API_URL}word/getOnlySubCategories?id=${categoryId}`)
            .catch((err) => {
                console.log("err: " + err);
                setSubCategories([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setSubCategories(response.data.subcategories);
                }
            });
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">
                {targetWord &&
                    <Typography variant='h3'>
                        Edit Word ({targetWord?.language[0].toUpperCase() + targetWord?.language.slice(1)})
                    </Typography>
                }
                {!targetWord &&
                    <Typography variant='h3'>
                        New Word (Turkish)
                    </Typography>
                }
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={wordSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Word"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"english_word"}
                                        value={values.english_word}
                                        error={!!touched.english_word && !!errors.english_word}
                                        helperText={touched.english_word && errors.english_word}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Translation"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"translation"}
                                        value={values.translation}
                                        error={!!touched.translation && !!errors.translation}
                                        helperText={touched.translation && errors.translation}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    {(!targetWord || targetWord.language === "turkish") &&
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Definition"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"definition"}
                                            value={values.definition}
                                            error={!!touched.definition && !!errors.definition}
                                            helperText={touched.definition && errors.definition}
                                            sx={{ gridColumn: "span 4", marginTop: "20px" }}
                                        />}
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Example"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"example"}
                                        value={values.example}
                                        error={!!touched.example && !!errors.example}
                                        helperText={touched.example && errors.example}
                                        sx={{ gridColumn: "span 4", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Highlight"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"example_highlight"}
                                        value={values.example_highlight}
                                        error={!!touched.example_highlight && !!errors.example_highlight}
                                        helperText={touched.example_highlight && errors.example_highlight}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Example Translation"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"example_translation"}
                                        value={values.example_translation}
                                        error={!!touched.example_translation && !!errors.example_translation}
                                        helperText={touched.example_translation && errors.example_translation}
                                        sx={{ gridColumn: "span 4", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Highlight Translation"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"example_translation_highlight"}
                                        value={values.example_translation_highlight}
                                        error={!!touched.example_translation_highlight && !!errors.example_translation_highlight}
                                        helperText={touched.example_translation_highlight && errors.example_translation_highlight}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    <Box sx={{ gridColumn: "span 4" }}></Box>
                                    {(!targetWord || targetWord.language === "turkish") &&
                                        <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2" }}>
                                            <InputLabel id="filter-label">Category</InputLabel>
                                            <Select
                                                labelId="filter-label"
                                                label="Category"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    setFieldValue("category", e.target.value);
                                                    setFieldValue("subcategory", '');
                                                    fetchSubcategories(e.target.value);
                                                }}
                                                name={"category"}
                                                value={values.category}
                                                error={!!touched.category && !!errors.category}
                                            >
                                                <MenuItem value={1}>A1</MenuItem>
                                                <MenuItem value={2}>A2</MenuItem>
                                                <MenuItem value={3}>B1</MenuItem>
                                                <MenuItem value={4}>B2</MenuItem>
                                                <MenuItem value={5}>C1</MenuItem>
                                                <MenuItem value={6}>C2</MenuItem>
                                                <MenuItem value={7}>Academic</MenuItem>
                                                <MenuItem value={14}>Phrasal Verbs</MenuItem>
                                                <MenuItem value={15}>Idioms</MenuItem>
                                            </Select>
                                        </FormControl>}
                                    {(!targetWord || targetWord.language === "turkish") && values.category && subCategories && subCategories.length > 0 &&
                                        <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2" }}>
                                            <InputLabel id="filter-label">Subcategory</InputLabel>
                                            <Select
                                                labelId="filter-label"
                                                label="Subcategory"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"subcategory"}
                                                value={values.subcategory}
                                                error={!!touched.subcategory && !!errors.subcategory}
                                            >
                                                {subCategories.map((sc) =>
                                                    <MenuItem key={sc.id} value={sc.id}>{sc.name}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    }
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default WordDialog