import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from '../../global/Header';
import axios from 'axios';
import { useContext } from 'react';
import { AdminContext } from '../../context/AdminContext';
import AdminBox from './AdminBox';
import AdminDialog from './AdminDialog';
import { Formik } from 'formik';
import * as yup from "yup";

function AdminsPage() {
    const { setToken, admin, token, isLoading } = useContext(AdminContext);
    const [admins, setAdmins] = useState([]);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [targetAdmin, setTargetAdmin] = useState(null);

    const [dialogPasswordUpdateOpen, setPasswordUpdateDialogOpen] = useState(false);
    const [targetAdminToUpdatePassword, setTargetAdminToUpdatePassword] = useState(null);

    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [adminToRemove, setAdminToRemove] = useState(null);

    const fetchAdmins = () => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getAdmins?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                setAdmins([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setAdmins(response.data.admins.filter((adm) => adm.id !== admin.id));
                }
            });
    }

    useEffect(() => {
        fetchAdmins();
    }, []);

    const handleFormSubmit = async (values) => {
        if (targetAdmin) {
            await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateAdmin?token=${token}&adminId=${targetAdmin.id}`, {
                admin: {
                    ...values
                }
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchAdmins();
                    handleClose();
                }
            });
        } else {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/createAdmin?token=${token}`, {
                ...values
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchAdmins();
                    handleClose();
                } else if (response && response.data) {
                    if (response.data.status === 406) {
                        //TODO open snackbar and show username already exists message
                    }
                }
            });
        }
    }


    var passwordChangeInitialValues = {
        password: ""
    }

    var validationObject = {
        password: yup.string().required("required")
    }

    const passwordChangeSchema = yup.object().shape(validationObject);

    const handlePasswordUpdateFormSubmit = async (values) => {
        if (targetAdminToUpdatePassword) {
            await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updatePassword?token=${token}&adminId=${targetAdminToUpdatePassword.id}`, {
                ...values
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchAdmins();
                    handlePasswordUpdateDialogClose();
                }
            });
        }
    }

    useEffect(() => {
        if (targetAdmin) {
            setDialogOpen(true);
        }
    }, [targetAdmin]);

    const handleClose = async () => {
        setDialogOpen(false);
        setTargetAdmin(null);
    }

    const updateAdmin = (admin) => {
        setTargetAdmin(admin);
    }



    useEffect(() => {
        if (targetAdminToUpdatePassword) {
            setPasswordUpdateDialogOpen(true);
        }
    }, [targetAdminToUpdatePassword]);

    const handlePasswordUpdateDialogClose = async () => {
        setPasswordUpdateDialogOpen(false);
        setTargetAdminToUpdatePassword(null);
    }

    const updateAdminPassword = (admin) => {
        setTargetAdminToUpdatePassword(admin);
    }



    useEffect(() => {
        if (adminToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [adminToRemove]);

    const handleRemoveDialogClose = async () => {
        setRemoveDialogOpen(false);
        setAdminToRemove(null);
    }

    const handleAdminToRemove = (admin) => {
        if (admin) {
            setAdminToRemove(admin);
        }
    }

    const removeAdmin = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteAdmin?adminId=${adminToRemove.id}&token=${token}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                fetchAdmins();
                handleRemoveDialogClose();
            }
        });
    }

    return (
        <Box sx={{ p: "75px" }}>
            <AdminDialog targetAdmin={targetAdmin} dialogOpen={dialogOpen} handleClose={handleClose} handleFormSubmit={handleFormSubmit} />

            <Dialog open={dialogPasswordUpdateOpen} onClose={handlePasswordUpdateDialogClose}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Changing an administrator's password does not log out currently open sessions. If you are worried about security, please delete the administrator account completely.
                    </DialogContentText>
                    <Formik
                        onSubmit={handlePasswordUpdateFormSubmit}
                        initialValues={passwordChangeInitialValues}
                        validationSchema={passwordChangeSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="flex" justifyContent="center" mx="25px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"password"}
                                        value={values.password}
                                        error={!!touched.password && !!errors.password}
                                        helperText={touched.password && errors.password}
                                        sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>

            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Are you sure you want to delete the admin?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Admin account will be deleted. You cannot undo this operation, you can add the same admin again if you wish.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>Cancel</Button>
                    <Button onClick={() => removeAdmin()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <Box display="flex" alignItems="center" gap="20px">
                <Header title="Admins" subtitle="You can see and manage admin accounts from this page." />

                <Button variant='contained' color='success' sx={{ m: 1, width: '25ch' }} onClick={() => {
                    setDialogOpen(true);
                }} >
                    Add New Admin
                </Button>

            </Box>
            <Box mt="30px">
                <Box display="flex" flexWrap="wrap" gap="20px">
                    {admins.map(adm => <AdminBox key={adm.id} admin={adm} updateAdmin={updateAdmin} updateAdminPassword={updateAdminPassword} removeAdmin={handleAdminToRemove} />)}
                </Box>
            </Box>
        </Box>
    )
}

export default AdminsPage
