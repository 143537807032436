import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { tokens } from '../../../theme'
import MoreVertIcon from '@mui/icons-material/MoreVert';

function UpdateAnnouncementBox({ updateAnnouncement, updateUpdateAnnouncement, removeUpdateAnnouncement }) {
    const colors = tokens();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box width="500px">
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    updateUpdateAnnouncement(updateAnnouncement);
                }}>
                    Edit
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    removeUpdateAnnouncement(updateAnnouncement);
                }}>
                    Remove
                </MenuItem>
            </Menu>
            <Box position="relative" bgcolor={colors.grey[900]} p="20px" borderRadius="10px">
                <Box position="absolute" top={15} right={15}>
                    <IconButton
                        id="open-menu-button"
                        aria-controls={open ? 'menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <MoreVertIcon sx={{ scale: "1.2" }} />
                    </IconButton>
                </Box>
                <Box display="flex" alignItems="center" gap="10px">
                    <Box component="img" src={updateAnnouncement.photo_link ? updateAnnouncement.photo_link : ""} height="30px" />
                    <Typography variant='h3' fontWeight="600">
                        {updateAnnouncement.title ? updateAnnouncement.title : "-"}
                    </Typography>
                </Box>
                <Box component="hr" my="20px" border={"1px solid " + colors.grey[500]} />
                <Box mt="10px">
                    <Typography variant='h5' fontWeight={600} sx={{ textDecoration: "underline" }}>Description</Typography>
                    <Typography variant='h6' >{updateAnnouncement.content ? updateAnnouncement.content : "-"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" gap="50px" mt="20px">
                    <Box textAlign="center">
                        <Typography variant='h5' fontWeight={600} sx={{ textDecoration: "underline" }}>Version</Typography>
                        <Typography variant='h6' >{updateAnnouncement.version}</Typography>
                    </Box>
                    <Box textAlign="center">
                        <Typography variant='h5' fontWeight={600} sx={{ textDecoration: "underline" }}>Position</Typography>
                        <Typography variant='h6' >{updateAnnouncement.position}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default UpdateAnnouncementBox