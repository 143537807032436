import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from '../../global/Header';
import axios from 'axios';
import AnnouncementBox from './AnnouncementBox';
import AnnouncementDialog from './AnnouncementDialog';
import FeaturedAnnouncementDialog from './FeaturedAnnouncementDialog';
import InfiniteScroll from 'react-infinite-scroll-component';

function AnnouncementsPage() {
    const [language, setLanguage] = useState("turkish");
    const [announcements, setAnnouncements] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [featuredDialogOpen, setFeaturedDialogOpen] = useState(false);
    const [targetAnnouncement, setTargetAnnouncement] = useState(null);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [announcementToRemove, setAnnouncementToRemove] = useState(null);

    const fetchAnnouncements = () => {
        axios.get(`${process.env.REACT_APP_API_URL}announcement/getAnnouncements?language=${language}&startIndex=${startIndex}&limit=50`)
            .catch((err) => {
                console.log("err: " + err);
                setAnnouncements([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.announcement && response.data.announcement.length === 50);
                    if (startIndex === 0) {
                        setAnnouncements(response.data.announcements);
                    } else {
                        setAnnouncements((prev) => [...prev, ...response.data.announcements]);
                    }
                }
            });
    }

    useEffect(() => {
        fetchAnnouncements();
    }, [language, startIndex]);

    const handleFormSubmit = async (values) => {
        if (targetAnnouncement) {
            await axios.patch(`${process.env.REACT_APP_API_URL}announcement/updateAnnouncement?announcementId=${targetAnnouncement.id}`, {
                announcement: values
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleClose();
                    if (startIndex === 0) {
                        fetchAnnouncements();
                    } else {
                        setStartIndex(0);
                    }
                }
            });
        } else {
            await axios.post(`${process.env.REACT_APP_API_URL}announcement/createAnnouncement`, {
                announcement: values
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleClose();
                    if (startIndex === 0) {
                        fetchAnnouncements();
                    } else {
                        setStartIndex(0);
                    }
                }
            });
        }
    }

    useEffect(() => {
        if (targetAnnouncement) {
            setDialogOpen(true);
        }
    }, [targetAnnouncement]);

    const handleClose = async () => {
        setDialogOpen(false);
        setTargetAnnouncement(null);
    }


    const updateAnnouncement = (announcement) => {
        setTargetAnnouncement(announcement);
    }

    useEffect(() => {
        if (announcementToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [announcementToRemove]);

    const handleRemoveDialogClose = async () => {
        setRemoveDialogOpen(false);
        setAnnouncementToRemove(null);
    }

    const handleAnnouncementToRemove = (announcement) => {
        setAnnouncementToRemove(announcement);
    }

    const removeAnnouncement = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}announcement/deleteAnnouncement?announcementId=${announcementToRemove.id}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                if (startIndex === 0) {
                    fetchAnnouncements();
                } else {
                    setStartIndex(0);
                }
                handleRemoveDialogClose();
            }
        });
    }

    return (
        <Box sx={{ p: "75px" }}>
            <AnnouncementDialog targetAnnouncement={targetAnnouncement} dialogOpen={dialogOpen} handleClose={handleClose} handleFormSubmit={handleFormSubmit} />
            <FeaturedAnnouncementDialog dialogOpen={featuredDialogOpen} handleClose={() => setFeaturedDialogOpen(false)} />
            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Are you sure you want to delete the announcement?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You cannot undo this operation, you can add the same announcement again if you wish.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>Cancel</Button>
                    <Button onClick={() => removeAnnouncement()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <Box display="flex" alignItems="center" gap="20px">
                <Header title="Announcements" subtitle="You can see and manage announcements from this page." />


                <Button variant='contained' color='success' sx={{ m: 1, width: '35ch' }} onClick={() => {
                    setDialogOpen(true);
                }} >
                    Add New Announcement
                </Button>

                <Button variant='contained' color='warning' sx={{ m: 1, py: "8px", fontSize: "10px" }} onClick={() => {
                    setFeaturedDialogOpen(true);
                }} >
                    Edit Featured Announcements
                </Button>

            </Box>
            <Box display="flex" alignItems="center" gap="20px">
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="language">Language</InputLabel>
                    <Select
                        id="language"
                        value={language}
                        label="Language"
                        onChange={(e) => {
                            setLanguage(e.target.value);
                        }}
                    >
                        <MenuItem value={"turkish"}>Turkish</MenuItem>
                        <MenuItem value={"french"}>French</MenuItem>
                        <MenuItem value={"german"}>German</MenuItem>
                        <MenuItem value={"spanish"}>Spanish</MenuItem>
                        <MenuItem value={"portuguese"}>Portuguese</MenuItem>
                        <MenuItem value={"italian"}>Italian</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box mt="30px">
                <Box mt="30px">
                    <InfiniteScroll
                        dataLength={announcements.length}
                        next={() => {
                            setStartIndex(announcements.length);
                        }}
                        hasMore={hasMore}
                        loader={<h4>Loading...</h4>}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>You are viewing all announcements.</b>
                            </p>
                        }
                    >
                        <Box display="flex" flexWrap="wrap" gap="20px">
                            {announcements.map(announcement => <AnnouncementBox key={announcement.id} announcement={announcement} updateAnnouncement={updateAnnouncement} removeAnnouncement={handleAnnouncementToRemove} />)}
                        </Box>
                    </InfiniteScroll>
                </Box>
            </Box>
        </Box>
    )
}

export default AnnouncementsPage
