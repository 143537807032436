import { Box, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CouponBox from './CouponBox';
import { AdminContext } from '../../../context/AdminContext';
import Header from '../../../global/Header';
import UserCouponBox from './UserCouponBox';
import { Search } from '@mui/icons-material';
import { tokens } from '../../../theme';

function CouponsPage() {
    const { token } = useContext(AdminContext);
    const [coupons, setCoupons] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [searchKey, setSearchKey] = useState("");
    const { userId } = useParams();

    const fetchCoupons = () => {
        axios.get(`${process.env.REACT_APP_API_URL}coupon/getUsedCoupons?${userId == -1 ? `searchKey=${searchKey}&` : `userId=${userId}&`}startIndex=${startIndex}&limit=50&token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                setCoupons([]);
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.coupons && response.data.coupons.length === 50);
                    if (startIndex === 0) {
                        setCoupons(response.data.coupons);
                    } else {
                        setCoupons((prev) => [...prev, ...response.data.coupons]);
                    }
                }
            });
    }



    useEffect(() => {
        fetchCoupons();
    }, [startIndex]);

    return (
        <Box sx={{ p: "75px" }}>
            <Box display="flex" alignItems="center" gap="20px" >
                <Header title={userId === "-1" ? "Coupon usages" : `Coupons usages of the user (id: ${userId})`} subtitle="You can see used coupons from this page." />
                {userId === "-1" &&
                    <FormControl sx={{ m: 1, width: '40ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box">Search (Code, Title or Username)</InputLabel>
                        <OutlinedInput
                            id="search-box"
                            type='text'
                            onChange={(e) => {
                                setSearchKey(e.target.value ? e.target.value : "");
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="search button"
                                        onClick={() => {
                                            setCoupons([]);
                                            if (startIndex === 0) {
                                                fetchCoupons();
                                            } else {
                                                setStartIndex(0);
                                            }
                                        }}
                                        edge="end"
                                    >
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Search"
                        />
                    </FormControl>
                }
            </Box>
            <Box mt="30px">
                <InfiniteScroll
                    dataLength={coupons.length}
                    next={() => {
                        setStartIndex(coupons.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>You are viewing all used coupons.</b>
                        </p>
                    }
                >
                    <Box display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
                        {coupons.map(coupon => {
                            if (userId == -1) {
                                return <CouponBox key={coupon.id} coupon={coupon} />
                            } else {
                                return <UserCouponBox key={coupon.id} coupon={coupon} />
                            }
                        })}
                    </Box>
                </InfiniteScroll>
            </Box>
        </Box>
    )
}

export default CouponsPage
