import { Route, Routes } from "react-router-dom";
import Sidebar from "./global/Sidebar";
import { useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useContext } from "react";
import { AdminContext } from "./context/AdminContext";
import LoginPage from "./pages/LoginPage";
import UsersPage from "./pages/UsersPage";
import QuestionsPage from "./pages/QuestionsPage";
import WordsPage from "./pages/WordsPage";
import WordSetsPage from "./pages/WordSetsPage";
import WordSetEditPage from "./pages/WordSetsPage/WordSetEditPage";
import WordCategoriesPage from "./pages/WordCategoriesPage";
import UpdateAnnouncementsPage from "./pages/UpdateAnnouncementsPage";
import AnnouncementsPage from "./pages/AnnouncementsPage";
import Topbar from "./global/Topbar";
import AdminsPage from "./pages/AdminsPage";
import GoldSettingsPage from "./pages/GoldSettingsPage";
import ReadingsPage from "./pages/ReadingsPage";
import ReadingAddPage from "./pages/ReadingsPage/ReadingAddPage";
import ReadingCategoriesPage from "./pages/ReadingsPage/ReadingCategoriesPage";
import CampaingsPage from "./pages/CampaingsPage";
import ReadingExamCategoriesPage from "./pages/ReadingsPage/ReadingExamCategoriesPage";
import CouponsPage from "./pages/CampaingsPage/CouponsPage";

function App() {
  const { setToken, admin, token, isLoading } = useContext(AdminContext);
  const theme = useMode();
  if (admin && token) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Topbar />
        <div className="app">
          <Sidebar />
          <main className="content pl80">
            <Routes>
              {admin.users_perm === 1 && <Route exact path="/" element={<UsersPage />} />}
              {admin.users_perm === 1 && <Route exact path="/users" element={<UsersPage />} />}
              {admin.questions_perm === 1 && <Route exact path="/questions" element={<QuestionsPage />} />}
              {admin.words_perm === 1 && <Route exact path="/words" element={<WordsPage />} />}
              {admin.words_perm === 1 && <Route exact path="/word-categories/*" element={<WordCategoriesPage />} />}
              {admin.wordsets_perm === 1 && <Route exact path="/wordsets" element={<WordSetsPage />} />}
              {admin.wordsets_perm === 1 && <Route exact path="/wordsets/*" element={<WordSetEditPage />} />}
              {admin.words_perm === 1 && <Route exact path="/readings" element={<ReadingsPage />} />}
              {admin.words_perm === 1 && <Route exact path="/reading-categories" element={<ReadingCategoriesPage />} />}
              {admin.words_perm === 1 && <Route exact path="/reading-exam-categories" element={<ReadingExamCategoriesPage />} />}
              {admin.words_perm === 1 && <Route exact path="/readings/new" element={<ReadingAddPage />} />}
              {admin.words_perm === 1 && <Route exact path="/readings/edit/:readingId/:editType" element={<ReadingAddPage />} />}
              {admin.update_announcements_perm === 1 && <Route exact path="/update-announcements" element={<UpdateAnnouncementsPage />} />}
              {admin.announcements_perm === 1 && <Route exact path="/announcements" element={<AnnouncementsPage />} />}
              {admin.admins_perm === 1 && <Route exact path="/admins" element={<AdminsPage />} />}
              {admin.settings_perm === 1 && <Route exact path="/gold-settings" element={<GoldSettingsPage />} />}
              {admin.settings_perm === 1 && <Route exact path="/campaigns" element={<CampaingsPage />} />}
              {admin.users_perm === 1 && <Route exact path="/campaigns/:userId" element={<CouponsPage />} />}
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    );
  } else if (!isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content" >
            <LoginPage />
          </main>
        </div>
      </ThemeProvider>
    )
  }
}

export default App;
