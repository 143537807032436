import React, { useContext, useEffect, useState } from 'react'
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material'
import axios from 'axios';
import { Formik } from 'formik';
import * as yup from "yup";
import Header from '../../global/Header';
import SubCategoryBox from './SubCategoryBox';
import SubCategoryDialog from './SubCategoryDialog';
import { AdminContext } from '../../context/AdminContext';

function WordCategoriesPage() {
    const { token } = useContext(AdminContext);

    const [category, setCategory] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [targetSubCategory, setTargetSubCategory] = useState(null);
    const [subCategoryToRemove, setSubCategoryToRemove] = useState(null);
    const [subCategoryDialogOpen, setSubCategoryDialogOpen] = useState(false);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

    var initialValues = {
        is_pro: category?.is_pro !== null && category?.is_pro !== undefined ? category?.is_pro : "",
        is_word_quiz_pro: category?.is_word_quiz_pro !== null && category?.is_word_quiz_pro !== undefined ? category?.is_word_quiz_pro : "",
    }

    var validationObject = {
        is_pro: yup.number().min(0, "Please Select").required("required"),
        is_word_quiz_pro: yup.number().min(0, "Please Select").required("required")
    }

    const categorySchema = yup.object().shape(validationObject);

    const fetchCategoryWithSubCategories = () => {
        axios.get(`${process.env.REACT_APP_API_URL}word/fetchCategoryWithSubCategories?categoryId=${window.location.pathname.split('/')[2]}`)
            .catch((err) => {
                console.log("err: " + err);
                setCategory(null);
                setSubCategories([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setCategory(response.data.category);
                    setSubCategories(response.data.category.subCategories);
                }
            });
    }

    useEffect(() => {
        fetchCategoryWithSubCategories();
    }, []);

    const handleFormSubmit = async (values) => {
        //TODO Update Category
        // await axios.patch(`${process.env.REACT_APP_API_URL}wordset/updateWordSet?wordSetId=${wordSet.id}`, {
        //     wordSet: {
        //         ...values
        //     }
        // }).catch((err) => {
        //     console.log("err: " + err);
        // }).then((response) => {
        //     if (response && response.data.success === true && response.data.status === 201) {
        //         setCategory(prev => ({ ...prev, ...values }))
        //         setOpenSnackBar(true);
        //     }
        // });
    }

    const editSubCategory = (subct) => {
        setTargetSubCategory(subct);
    }

    const handleRemoveDialogClose = () => {
        setSubCategoryToRemove(null);
        setRemoveDialogOpen(false);
    }

    useEffect(() => {
        if (subCategoryToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [subCategoryToRemove]);

    const removeSubCategory = async () => {
        if (subCategoryToRemove.language === "turkish") {
            await axios.delete(`${process.env.REACT_APP_API_URL}word/deleteSubCategory?id=${subCategoryToRemove.id}`).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    fetchCategoryWithSubCategories()
                    handleRemoveDialogClose();
                }
            });
        } else {
            await axios.delete(`${process.env.REACT_APP_API_URL}word/deleteSubCategoryTranslation?id=${subCategoryToRemove.id}&language=${subCategoryToRemove.language}`).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    fetchCategoryWithSubCategories()
                    handleRemoveDialogClose();
                }
            });
        }

    }

    useEffect(() => {
        if (targetSubCategory) {
            setSubCategoryDialogOpen(true);
        }
    }, [targetSubCategory]);


    const subCategoryDialogHandleClose = () => {
        setTargetSubCategory(null);
        setSubCategoryDialogOpen(false);
    }

    const subCategoryDialogHandleSubmit = async (values) => {
        if (targetSubCategory) {
            if (targetSubCategory.language === "turkish") {
                if (values.thumbnail) {
                    await axios.post(`${process.env.REACT_APP_API_URL}word/uploadSubCategoryThumbnail?token=${token}`, {
                        thumbnail: values.thumbnail
                    }, {
                        headers: {
                            "content-type": "multipart/form-data"
                        }
                    })
                        .catch((err) => {
                            console.log("err: " + err);
                        })
                        .then((response) => {
                            if (response && response.data.success === true && response.data.status === 201) {
                                values.thumbnail_path = response.data.thumbnailPath;
                            }
                        });
                }
                delete values.thumbnail_link;
                delete values.thumbnail;
                console.log(values.thumbnail_path);
                await axios.patch(`${process.env.REACT_APP_API_URL}word/updateSubCategory?subCategoryId=${targetSubCategory.id}`, {
                    name: values.name,
                    is_pro: values.is_pro,
                    thumbnail_path: values.thumbnail_path ? values.thumbnail_path : targetSubCategory.thumbnail_path,
                    ...(targetSubCategory.category !== values.category ? { category: values.category } : {})
                }).catch((err) => {
                    console.log("err: " + err);
                }).then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        fetchCategoryWithSubCategories();
                        subCategoryDialogHandleClose();
                    }
                });
            } else {
                if (targetSubCategory.name) {
                    await axios.patch(`${process.env.REACT_APP_API_URL}word/updateSubCategoryTranslationWithSubCtIdAndLanguage`, {
                        id: targetSubCategory.id,
                        content: values.name,
                        language: targetSubCategory.language
                    }).catch((err) => {
                        console.log("err: " + err);
                    }).then((response) => {
                        if (response && response.data.success === true && response.data.status === 200) {
                            fetchCategoryWithSubCategories();
                            subCategoryDialogHandleClose();
                        }
                    });
                } else {
                    await axios.post(`${process.env.REACT_APP_API_URL}word/addSubCategoryTranslation`, {
                        subcategoryId: targetSubCategory.id,
                        content: values.name,
                        language: targetSubCategory.language,
                    }).catch((err) => {
                        console.log("err: " + err);
                    }).then((response) => {
                        if (response && response.data.success === true && response.data.status === 201) {
                            subCategoryDialogHandleClose();
                            fetchCategoryWithSubCategories()
                        }
                    });
                }
            }
        } else {
            if (values.thumbnail) {
                await axios.post(`${process.env.REACT_APP_API_URL}word/uploadSubCategoryThumbnail?token=${token}`, {
                    thumbnail: values.thumbnail
                }, {
                    headers: {
                        "content-type": "multipart/form-data"
                    }
                })
                    .catch((err) => {
                        console.log("err: " + err);
                    })
                    .then((response) => {
                        if (response && response.data.success === true && response.data.status === 201) {
                            values.thumbnail_path = response.data.thumbnailPath;
                        }
                    });
            }
            delete values.thumbnail_link;
            delete values.thumbnail;
            await axios.post(`${process.env.REACT_APP_API_URL}word/addSubCategoryWithDetails`, {
                ...values
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    subCategoryDialogHandleClose();
                    fetchCategoryWithSubCategories()
                }
            });
        }
    }

    return (
        <Box sx={{ p: "75px" }}>
            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{subCategoryToRemove?.language === "turkish" ? "Are you sure you want to delete the subcategory?" : "Are you sure you want to delete the subcategory's translation?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {subCategoryToRemove?.language === "turkish" ?
                            "Subcategory and all related data (words etc.) will be deleted. You cannot undo this operation, you can add new subcategory with same name again if you wish."
                            :
                            "You cannot undo this operation, you can add new subcategory translation with same name again if you wish."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>Cancel</Button>
                    <Button onClick={() => removeSubCategory()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <SubCategoryDialog subCategory={targetSubCategory} dialogOpen={subCategoryDialogOpen} handleClose={subCategoryDialogHandleClose} handleFormSubmit={subCategoryDialogHandleSubmit} />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openSnackBar}
                autoHideDuration={5000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert onClose={() => setOpenSnackBar(false)} severity="success" sx={{ width: '100%' }}>
                    Saved!
                </Alert>
            </Snackbar>
            <Box display="flex" alignItems="center" gap="20px">
                <Header title={category?.name} subtitle="" />
            </Box>
            <Box>
                {category &&
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={categorySchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="flex" alignItems="center" gap="15px">
                                    <FormControl variant="filled" sx={{ minWidth: "200px" }}>
                                        <InputLabel id="filter-label">Usage Limit</InputLabel>
                                        <Select
                                            labelId="filter-label"
                                            label="Usage Limit"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"is_pro"}
                                            value={values.is_pro}
                                            error={!!touched.is_pro && !!errors.is_pro}
                                        >
                                            <MenuItem value={0}>Everyone</MenuItem>
                                            <MenuItem value={1}>Premium Users</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="filled" sx={{ minWidth: "200px" }}>
                                        <InputLabel id="filter-label1">Word Quiz Usage Limit</InputLabel>
                                        <Select
                                            labelId="filter-label1"
                                            label="Word Quiz Usage Limit"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"is_word_quiz_pro"}
                                            value={values.is_word_quiz_pro}
                                            error={!!touched.is_word_quiz_pro && !!errors.is_word_quiz_pro}
                                        >
                                            <MenuItem value={0}>Everyone</MenuItem>
                                            <MenuItem value={1}>Premium Users</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Button type="submit" color="success" variant="contained" >
                                        Save
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                }
            </Box>
            <Box mt="40px">
                <Box display="flex" alignItems="center" gap="10px">
                    <Typography variant='h3'>Related Subcategories</Typography>
                    <Button variant='contained' color='success' sx={{ m: 1, width: '30ch' }} onClick={() => {
                        setSubCategoryDialogOpen(true);
                    }} >
                        Add New Subcategory
                    </Button>
                </Box>
                <Box display="flex" flexWrap="wrap" gap="20px" mt="20px">
                    {subCategories.map(subct => <SubCategoryBox key={subct.id + category.name} subCategory={subct} category={category} editSubCategory={editSubCategory} removeSubCategory={(subct) => setSubCategoryToRemove(subct)} fetchSubCategories={fetchCategoryWithSubCategories} />)}
                </Box>
            </Box>
        </Box >
    )
}

export default WordCategoriesPage