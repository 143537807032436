import React, { useEffect, useState } from 'react'
import Header from '../../../global/Header'
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material'
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Formik } from 'formik';
import * as yup from "yup";
import WordSetWordBox from '../WordSetWordBox';
import WordSetWordDialog from '../WordSetWordDialog';

function WordSetEditPage() {
    const [wordSet, setWordSet] = useState(null);
    const [wordSetWords, setWordSetWords] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [targetWord, setTargetWord] = useState(null);
    const [wordToRemove, setWordToRemove] = useState(null);
    const [wordDialogOpen, setWordDialogOpen] = useState(false);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

    var initialValues = {
        name: wordSet?.name ? wordSet?.name : "",
        privacy: wordSet?.privacy !== null && wordSet?.privacy !== undefined ? wordSet?.privacy : "",
    }

    var validationObject = {
        name: yup.string().required("required"),
        privacy: yup.number().min(0, "Please Select").required("required")
    }

    const wordSetSchema = yup.object().shape(validationObject);

    const fetchWordSet = () => {
        axios.get(`${process.env.REACT_APP_API_URL}wordset/getWordSetDetailsForWebPanel?wordSetId=${window.location.pathname.split('/')[2]}&startIndex=${startIndex}&limit=50`)
            .catch((err) => {
                console.log("err: " + err);
                setWordSet(null);
                setWordSetWords([]);
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setWordSet(response.data.wordSet);
                    setHasMore(response.data.wordSet.words && response.data.wordSet.words.length === 50);
                    if (startIndex === 0) {
                        setWordSetWords(response.data.wordSet.words);
                    } else {
                        setWordSetWords((prev) => [...prev, ...response.data.wordSet.words]);
                    }
                }
            });
    }

    useEffect(() => {
        fetchWordSet();
    }, [startIndex])

    const handleFormSubmit = async (values) => {
        await axios.patch(`${process.env.REACT_APP_API_URL}wordset/updateWordSet?wordSetId=${wordSet.id}`, {
            wordSet: {
                ...values
            }
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                setWordSet(prev => ({ ...prev, ...values }))
                setOpenSnackBar(true);
            }
        });
    }

    const editWord = (word) => {
        setTargetWord(word);
    }

    const handleRemoveDialogClose = () => {
        setWordToRemove(null);
        setRemoveDialogOpen(false);
    }

    useEffect(() => {
        if (wordToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [wordToRemove]);

    const removeWord = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}wordset/deleteWord?wordId=${wordToRemove.id}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                if (startIndex === 0) {
                    fetchWordSet();
                } else {
                    setStartIndex(0);
                }
                handleRemoveDialogClose();
            }
        });
    }

    useEffect(() => {
        if (targetWord) {
            setWordDialogOpen(true);
        }
    }, [targetWord]);


    const wordDialogHandleClose = () => {
        setTargetWord(null);
        setWordDialogOpen(false);
    }

    const wordDialogHandleSubmit = async (values) => {
        if (targetWord) {
            //edit
            await axios.patch(`${process.env.REACT_APP_API_URL}wordset/updateWord?wordId=${targetWord.id}`, {
                word: {
                    ...values
                }
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    wordDialogHandleClose();
                    if (startIndex === 0) {
                        fetchWordSet();
                    } else {
                        setStartIndex(0);
                    }
                }
            });
        } else {
            //add
            await axios.post(`${process.env.REACT_APP_API_URL}wordset/createWord`, {
                word: {
                    ...values,
                    word_set_id: wordSet.id,
                    language: wordSet.language
                }
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    wordDialogHandleClose();
                    if (startIndex === 0) {
                        fetchWordSet();
                    } else {
                        setStartIndex(0);
                    }
                }
            });
        }
    }

    return (
        <Box sx={{ p: "75px" }}>
            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{"Are you sure you want to delete the word?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You cannot undo this operation, you can add the same word to the word set again if you wish.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>Cancel</Button>
                    <Button onClick={() => removeWord()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <WordSetWordDialog targetWord={targetWord} dialogOpen={wordDialogOpen} handleClose={wordDialogHandleClose} handleFormSubmit={wordDialogHandleSubmit} />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openSnackBar}
                autoHideDuration={5000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert onClose={() => setOpenSnackBar(false)} severity="success" sx={{ width: '100%' }}>
                    Saved!
                </Alert>
            </Snackbar>
            <Box display="flex" alignItems="center" gap="20px">
                <Header title={wordSet?.name} subtitle="" />
            </Box>
            <Box>
                {wordSet &&
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={wordSetSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="flex" alignItems="center" gap="15px">
                                    <TextField
                                        variant="filled"
                                        type="text"
                                        label="Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"name"}
                                        value={values.name}
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        sx={{ minWidth: "200px" }}
                                    />
                                    <FormControl variant="filled" sx={{ minWidth: "200px" }}>
                                        <InputLabel id="filter-label">Privacy</InputLabel>
                                        <Select
                                            labelId="filter-label"
                                            label="Privacy"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"privacy"}
                                            value={values.privacy}
                                            error={!!touched.privacy && !!errors.privacy}
                                        >
                                            <MenuItem value={0}>Public</MenuItem>
                                            <MenuItem value={1}>Friends Only</MenuItem>
                                            <MenuItem value={2}>Private</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Button type="submit" color="success" variant="contained" >
                                        Save
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                }
            </Box>
            <Box mt="40px">
                <Box display="flex" alignItems="center" gap="10px">
                    <Typography variant='h3'>Word Set's Words</Typography>
                    <Button variant='contained' color='success' sx={{ m: 1, width: '25ch' }} onClick={() => {
                        setWordDialogOpen(true);
                    }} >
                        Add New Word
                    </Button>
                </Box>
                <InfiniteScroll
                    dataLength={wordSetWords.length}
                    next={() => {
                        setStartIndex(wordSetWords.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>You are viewing all words.</b>
                        </p>
                    }
                    style={{ marginTop: "20px" }}
                >
                    <Box display="flex" flexWrap="wrap" gap="20px">
                        {wordSetWords.map(w => <WordSetWordBox key={w.id} wordSetWord={w} editWord={editWord} removeWord={(word) => setWordToRemove(word)} />)}
                    </Box>
                </InfiniteScroll>
            </Box>
        </Box >
    )
}

export default WordSetEditPage