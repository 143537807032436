import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { tokens } from '../../../theme'
import MoreVertIcon from '@mui/icons-material/MoreVert';


function WordBox({ wordSetWord, editWord, removeWord }) {
    const colors = tokens();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box width="500px">
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    editWord(wordSetWord);
                }}>
                    Edit
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    removeWord(wordSetWord);
                }}>
                    Remove
                </MenuItem>
            </Menu>
            <Box position="relative" bgcolor={colors.grey[900]} p="20px" borderRadius="10px">
                <Box position="absolute" top={15} right={15}>
                    <IconButton
                        id="open-menu-button"
                        aria-controls={open ? 'menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <MoreVertIcon sx={{ scale: "1.2" }} />
                    </IconButton>
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <Box>
                        <Typography variant='h3' fontWeight="600">
                            {wordSetWord.word}
                        </Typography>
                        <Typography variant='h3'>
                            {wordSetWord.translation}
                        </Typography>
                    </Box>
                </Box>
                <Box component="hr" my="20px" border={"1px solid " + colors.grey[500]} />
                <Box mt="10px">
                    <Typography variant='h5' fontWeight={600} sx={{ textDecoration: "underline" }}>Definition</Typography>
                    <Typography variant='h6' >-{wordSetWord.definition}</Typography>
                    <Typography variant='h6' >-{wordSetWord.definition_translation}</Typography>
                </Box>
                <Box mt="10px">
                    <Typography variant='h5' fontWeight={600} sx={{ textDecoration: "underline" }}>Example</Typography>
                    <Typography variant='h6' >-{wordSetWord.example}</Typography>
                    <Typography variant='h6' >-{wordSetWord.example_translation}</Typography>
                </Box>
                <Box mt="10px">
                    <Typography variant='h5' fontWeight={600} sx={{ textDecoration: "underline" }}>Example</Typography>
                    <Typography variant='h6' >-{wordSetWord.synonyms}</Typography>
                    <Typography variant='h6' >-{wordSetWord.synonyms_translation}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default WordBox