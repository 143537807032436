import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { tokens } from '../../../theme';

function AnnouncementDialog({ targetAnnouncement, dialogOpen, handleClose, handleFormSubmit }) {
    const colors = tokens();

    var initialValues = {
        text: targetAnnouncement?.text ? targetAnnouncement?.text : "",
        action_text: targetAnnouncement?.action_text ? targetAnnouncement?.action_text : "",
        action_url: targetAnnouncement?.action_url ? targetAnnouncement?.action_url : "",
        language: targetAnnouncement?.language ? targetAnnouncement.language : ""
    }

    var validationObject = {
        text: yup.string().required("required"),
        action_text: yup.string().required("required"),
        action_url: yup.string().required("required"),
        language: yup.string().required("required"),
    }

    const announcementSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">
                {targetAnnouncement &&
                    <Typography variant='h3'>
                        Edit Announcement
                    </Typography>
                }
                {!targetAnnouncement &&
                    <Typography variant='h3'>
                        New Announcement
                    </Typography>
                }
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={announcementSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Action Text"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"action_text"}
                                        value={values.action_text}
                                        error={!!touched.action_text && !!errors.action_text}
                                        helperText={touched.action_text && errors.action_text}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Action URL"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"action_url"}
                                        value={values.action_url}
                                        error={!!touched.action_url && !!errors.action_url}
                                        helperText={touched.action_url && errors.action_url}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Text"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={4}
                                        name={"text"}
                                        value={values.text}
                                        error={!!touched.text && !!errors.text}
                                        helperText={touched.text && errors.text}
                                        sx={{ gridColumn: "span 4", marginTop: "10px" }}
                                    />

                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2", marginTop: "10px" }}>
                                        <InputLabel id="filter-label">Language</InputLabel>
                                        <Select
                                            labelId="filter-label"
                                            label="Language"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"language"}
                                            value={values.language}
                                            error={!!touched.language && !!errors.language}
                                        >
                                            <MenuItem value={"turkish"}>Turkish</MenuItem>
                                            <MenuItem value={"french"}>French</MenuItem>
                                            <MenuItem value={"german"}>German</MenuItem>
                                            <MenuItem value={"spanish"}>Spanish</MenuItem>
                                            <MenuItem value={"portuguese"}>Portuguese</MenuItem>
                                            <MenuItem value={"italian"}>Italian</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default AnnouncementDialog