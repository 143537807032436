import React, { useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';

function VersionSettingsDialog({ dialogOpen, handleClose, settings, fetchSettings }) {

    var initialValues = {
        version: settings?.version !== undefined && settings?.version !== null ? settings?.version : 0,
        is_maintenance: settings?.is_maintenance !== undefined && settings?.is_maintenance !== null ? settings?.is_maintenance : 0
    }

    var validationObject = {
        version: yup.number().min(0, "Can be at least 0").required("required"),
        is_maintenance: yup.number().min(0, "Please Select").required("required")
    }

    const settingsSchema = yup.object().shape(validationObject);

    const handleFormSubmit = async (values) => {
        if (settings.is_maintenance !== values.is_maintenance) {
            await axios.patch(`${process.env.REACT_APP_API_URL}settings/updateMaintenance`, {
                is_maintenance: values.is_maintenance
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                }
            });
        }
        if (settings.version !== values.version) {
            await axios.patch(`${process.env.REACT_APP_API_URL}settings/updateVersion`, {
                version: values.version
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    fetchSettings();
                    handleClose();
                }
            });
        }
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" mx="150px" textAlign="center">
                <Typography variant='h3'>
                    Version Settings
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={settingsSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mb="25px">

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Version"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"version"}
                                        value={values.version}
                                        error={!!touched.version && !!errors.version}
                                        helperText={touched.version && errors.version}
                                        sx={{ gridColumn: "span 2", marginTop: "10px" }}
                                    />

                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2", marginTop: "10px" }}>
                                        <InputLabel id="filter-label">Maintenance Mode</InputLabel>
                                        <Select
                                            labelId="filter-label"
                                            label="Maintenance Mode"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"is_maintenance"}
                                            value={values.is_maintenance}
                                            error={!!touched.is_maintenance && !!errors.is_maintenance}
                                        >
                                            <MenuItem value={0}>Passive</MenuItem>
                                            <MenuItem value={1}>Active</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default VersionSettingsDialog