import React, { useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import axios from 'axios';

function GoldWinnerCountDialog({ dialogOpen, handleClose, settings, fetchSettings }) {

    var initialValues = {
        goldPersonLimit: settings?.goldPersonLimit !== undefined && settings?.goldPersonLimit !== null ? settings?.goldPersonLimit : 0,
    }

    var validationObject = {
        goldPersonLimit: yup.number().min(0, "Can be at least 0").required("required"),
    }

    const settingsSchema = yup.object().shape(validationObject);

    const handleFormSubmit = async (values) => {
        await axios.patch(`${process.env.REACT_APP_API_URL}settings/updateGoldPersonLimit?limit=${values.goldPersonLimit}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                fetchSettings();
                handleClose();
                if (window.location.pathname.split("/")[1] === "gold-settings") {
                    window.location.reload(false);
                }
            }
        });
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" mx="150px" textAlign="center">
                <Typography variant='h3'>
                    Set Gold Winner Count
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={settingsSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mb="25px">

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Gold Winner Count"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"goldPersonLimit"}
                                        value={values.goldPersonLimit}
                                        error={!!touched.goldPersonLimit && !!errors.goldPersonLimit}
                                        helperText={touched.goldPersonLimit && errors.goldPersonLimit}
                                        sx={{ gridColumn: "span 2", marginTop: "10px" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default GoldWinnerCountDialog