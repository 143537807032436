import React, { useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';

function LoyaltyPrizeSettingsDialog({ dialogOpen, handleClose, settings, fetchSettings }) {

    var initialValues = {
        heart_prize: settings?.heart_prize !== undefined && settings?.heart_prize !== null ? settings?.heart_prize : 0,
        diamond_prize: settings?.diamond_prize !== undefined && settings?.diamond_prize !== null ? settings?.diamond_prize : 0,
        gold_prize: settings?.gold_prize !== undefined && settings?.gold_prize !== null ? settings?.gold_prize : 0,
        premium_prize: settings?.premium_prize !== undefined && settings?.premium_prize !== null ? settings?.premium_prize : "none"
    }

    var validationObject = {
        heart_prize: yup.number().min(0, "Can be at least 0").required("required"),
        diamond_prize: yup.number().min(0, "Can be at least 0").required("required"),
        gold_prize: yup.number().min(0, "Can be at least 0").required("required"),
        premium_prize: yup.string().min(0, "Please Select").required("required")
    }

    const settingsSchema = yup.object().shape(validationObject);

    const handleFormSubmit = async (values) => {
        await axios.patch(`${process.env.REACT_APP_API_URL}settings/updateLoyaltyPrizes`, {
            ...values
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                fetchSettings();
                handleClose();
            }
        });
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" mx="150px" textAlign="center">
                <Typography variant='h3'>
                    Loyalty Prize Settings
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={settingsSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mb="25px">

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Heart"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"heart_prize"}
                                        value={values.heart_prize}
                                        error={!!touched.heart_prize && !!errors.heart_prize}
                                        helperText={touched.heart_prize && errors.heart_prize}
                                        sx={{ gridColumn: "span 1", marginTop: "10px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Diamond"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"diamond_prize"}
                                        value={values.diamond_prize}
                                        error={!!touched.diamond_prize && !!errors.diamond_prize}
                                        helperText={touched.diamond_prize && errors.diamond_prize}
                                        sx={{ gridColumn: "span 1", marginTop: "10px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Gold"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"gold_prize"}
                                        value={values.gold_prize}
                                        error={!!touched.gold_prize && !!errors.gold_prize}
                                        helperText={touched.gold_prize && errors.gold_prize}
                                        sx={{ gridColumn: "span 1", marginTop: "10px" }}
                                    />

                                    <FormControl fullWidth variant="filled" sx={{ mt: "10px" }}>
                                        <InputLabel id="filter-label">Premium</InputLabel>
                                        <Select
                                            labelId="filter-label"
                                            label="Premium"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"premium_prize"}
                                            value={values.premium_prize}
                                            sx={{ gridColumn: "span 1" }}
                                        >
                                            <MenuItem value={"none"}>None</MenuItem>
                                            <MenuItem value={"monthly"}>Monthly</MenuItem>
                                            <MenuItem value={"six_month"}>6 Months</MenuItem>
                                            <MenuItem value={"yearly"}>Yearly</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default LoyaltyPrizeSettingsDialog