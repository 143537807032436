import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../global/Header';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import ReadingCategoryDialog from './ReadingCategoryDialog';
import ReadingCategoryBox from './ReadingCategoryBox';
import { AdminContext } from '../../../context/AdminContext';

function ReadingCategoriesPage() {
    const { token } = useContext(AdminContext);
    const [searchKey, setSearchKey] = useState("");
    const [readingCategories, setReadingCategories] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [readingCategoryToRemove, setReadingCategoryToRemove] = useState(null);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [targetCategory, setTargetCategory] = useState(null);
    const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);

    const fetchReadingCategories = () => {
        axios.get(`${process.env.REACT_APP_API_URL}reading/getReadingCategories?searchKey=${searchKey}&startIndex=${startIndex}&limit=50`)
            .catch((err) => {
                console.log("err: " + err);
                setReadingCategories([]);
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.categories && response.data.categories.length === 50);
                    if (startIndex === 0) {
                        console.log(response.data.categories);
                        setReadingCategories(response.data.categories);
                    } else {
                        setReadingCategories((prev) => [...prev, ...response.data.categories]);
                    }
                }
            });
    }

    useEffect(() => {
        fetchReadingCategories();
    }, [startIndex]);

    const handleRemoveDialogClose = async () => {
        setReadingCategoryToRemove(null);
        setRemoveDialogOpen(false);
    }

    const removeReadingCategory = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}reading/deleteReadingCategory?categoryId=${readingCategoryToRemove.id}&token=${token}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                if (startIndex === 0) {
                    fetchReadingCategories();
                } else {
                    setStartIndex(0);
                }
                handleRemoveDialogClose();
            }
        });
    }

    useEffect(() => {
        if (readingCategoryToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [readingCategoryToRemove])

    useEffect(() => {
        if (targetCategory) {
            setCategoryDialogOpen(true);
        }
    }, [targetCategory])

    return (
        <Box sx={{ p: "75px" }}>
            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{"Are you sure you want to delete the category?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You cannot undo this operation but you can add new category if you wish.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>Cancel</Button>
                    <Button onClick={() => removeReadingCategory()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <ReadingCategoryDialog targetCategory={targetCategory} dialogOpen={categoryDialogOpen} handleClose={(changed) => {
                setTargetCategory(null);
                setCategoryDialogOpen(false);
                if (changed) {
                    if (startIndex === 0) {
                        fetchReadingCategories();
                    } else {
                        setStartIndex(0);
                    }
                }

            }} />
            <Box display="flex" alignItems="center" gap="20px">

                <Header title="ReadingCategories" subtitle="You can see and manage reading categories from this page." />

                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="search-box">Search</InputLabel>
                    <OutlinedInput
                        id="search-box"
                        type='text'
                        onChange={(e) => {
                            setSearchKey(e.target.value ? e.target.value : "");
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="search button"
                                    onClick={() => {
                                        setReadingCategories([]);
                                        if (startIndex === 0) {
                                            fetchReadingCategories();
                                        } else {
                                            setStartIndex(0);
                                        }
                                    }}
                                    edge="end"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Search"
                    />
                </FormControl>
                <Button variant='contained' color='success' sx={{ m: 1, width: '25ch' }} onClick={() => {
                    setTargetCategory(null);
                    setCategoryDialogOpen(true);
                }} >
                    Add New Category
                </Button>
            </Box>
            <Box mt="30px">
                <InfiniteScroll
                    dataLength={readingCategories.length}
                    next={() => {
                        setStartIndex(readingCategories.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>You are viewing all categories.</b>
                        </p>
                    }
                >
                    <Box display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
                        {readingCategories.map(readingCategory => <ReadingCategoryBox key={readingCategory.id} readingCategory={readingCategory} editReadingCategory={(rc) => setTargetCategory(rc)} removeReadingCategory={(rc) => setReadingCategoryToRemove(rc)} />)}
                    </Box>
                </InfiniteScroll>
            </Box>
        </Box>
    )
}

export default ReadingCategoriesPage
